import { AppSettings } from '../helpers/appSettings';
import axios from 'axios';

export class VidApi {
  protected apiAddress: any;

  constructor() {
    this.apiAddress = AppSettings.Get('apiAddress');
  }

  protected async getApiURL() {
    return this.apiAddress;
  }

  private getToken(): any {
    return localStorage.getItem('authentication-token');
  }

  protected createAuthBearer(): string {
    const token = this.getToken();
    return token != null ? 'Bearer ' + this.getToken() : '';
  }

  protected async Get(method: string, source: string): Promise<any> {
    const url = await this.getApiURL();
    console.log(url + method);
    return axios
      .get(url + method, {
        headers: {
          Authorization: this.createAuthBearer(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'isRefreshToken':'true',
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        if (error != null) {
          console.dir(error);
          this.webConnectionError(error, source);
          throw error;
        }
        return null;
      });
  }

  protected async Delete(method: string, source: string): Promise<any> {
    const url = await this.getApiURL();
    console.log(url + method);
    return axios
      .delete(url + method, {
        headers: {
          Authorization: this.createAuthBearer(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'isRefreshToken':'true',
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        if (error != null) {
          console.dir(error);
          this.webConnectionError(error, source);
          throw error;
        }
        return null;
      });
  }

  protected async Post(method: string, source: string): Promise<any> {
    return fetch((await this.getApiURL()) + method, {
      method: 'post',
      headers: {
        Authorization: this.createAuthBearer(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'isRefreshToken':'true',
      },
    })
      .then((response) => this.filterOnStatus(response).json())
      .catch((error) => {
        if (error != null) {
          console.dir(error);
          this.webConnectionError(error, source);
          throw error;
        }
        return null;
      });
  }

  protected async PostObject(
    method: string,
    source: string,
    object: any
  ): Promise<any> {
    return fetch((await this.getApiURL()) + method, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'isRefreshToken':'true',
      },
      body: JSON.stringify(object),
    })
      .then((response) => this.filterOnStatus(response).json())
      .catch((error) => {
        if (error != null) {
          console.dir(error);
          this.webConnectionError(error, source);
          throw error;
        }
        return null;
      });
  }
  protected async PostObjectAuth(
    method: string,
    source: string,
    object: any
  ): Promise<any> {
    return fetch((await this.getApiURL()) + method, {
      method: 'post',
      headers: {
        Authorization: this.createAuthBearer(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'isRefreshToken':'true',
      },
      body: JSON.stringify(object),
    })
      .then((response) => this.filterOnStatus(response).json())
      .catch((error) => {
        if (error != null) {
          console.dir(error);
          this.webConnectionError(error, source);
          throw error;
        }
        return null;
      });
  }

  protected async PutbOjectAuth(
    method: string,
    source: string,
    object: any
  ): Promise<any> {
    return fetch((await this.getApiURL()) + method, {
      method: 'put',
      headers: {
        Authorization: this.createAuthBearer(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'isRefreshToken':'true',
      },
      body: JSON.stringify(object),
    })
      .then((response) => this.filterOnStatus(response).json())
      .catch((error) => {
        if (error != null) {
          console.dir(error);
          this.webConnectionError(error, source);
          throw error;
        }
        return null;
      });
  }

  protected async DeleteObjectAuth(
    method: string,
    source: string,
    object: any
  ): Promise<any> {
    return fetch((await this.getApiURL()) + method, {
      method: 'delete',
      headers: {
        Authorization: this.createAuthBearer(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'isRefreshToken':'true',
      },
      body: JSON.stringify(object),
    })
      .then((response) => this.filterOnStatus(response).json())
      .catch((error) => {
        if (error != null) {
          console.dir(error);
          this.webConnectionError(error, source);
          throw error;
        }
        return null;
      });
  }

  public webConnectionError(error: any, source: string) {
    //console.log("ERROR", error, source);

    const msg = {
      type: 'danger',
      label: '',
      text: 'Cannot connect to API',
      icon: 'exclamation-triangle',
      timeout: 10,
      error: error.toString() + ' @' + source,
    };
    const c_event = new CustomEvent('addAlert', { detail: msg });
    window.dispatchEvent(c_event);
    // this.dispatch(setErrorMessage(msg.text));
    // alert('Fel vid hämtning från servern!');
  }

  protected filterOnStatus(resp: Response): Response {
    if (!resp.ok) {
      const msg = {
        type: 'danger',
        label: '',
        text: '..',
        icon: 'exclamation-triangle',
        timeout: 5,
      };
      if (resp.status === 401) {
        //  this.logout();
        msg.text =
          '..';
      } else if (resp.status === 400) {
        msg.text =
          '..';
      } else if (resp.status === 404) {
        msg.text =
          '..';
      } else {
        msg.text =
          '.. ' +
          resp.status;
        //console.log("error:" + resp.status + " " + resp.statusText);
      }
      
      throw null;
    }
    return resp;
  }
  // constructor() {
  //     this.list.push({id: 1, number: "1", name: "Item 1"});
  //     this.list.push({id: 2, number: "2", name: "Item 2"});
  //     this.list.push({id: 3, number: "3", name: "Item 3"});
  //     this.list.push({id: 4, number: "4", name: "Item 4"});
  //     this.list.push({id: 5, number: "5", name: "Item 5"});

  // }
}

export class HttpError extends Error {
  // (1)
  public json: any;
  constructor(response: { status: any; url: any; json: () => any }) {
    super(`${response.status} for ${response.url}`);
    this.name = 'HttpError';
    this.json = response.json();
  }
}
