import React, { useEffect } from 'react';

import { Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/img_logo_assist.png';
import { useHistory } from 'react-router-dom';
import { UserDto } from '../models/UserDto';
import {
  setAuthentication,
  setCurrentUser,
  setShowSingUp,
} from '../redux/actions/app';
import './headbanner.css';
import { IsAuthenticated } from '../helpers/withAuthentication';
//import userGreen from '../assets/images/ic_user_green.svg';

const HeadBanner: React.FC = () => {
  const user: UserDto = useSelector((state) => state['app'].currentUser);
  const isAuthenticated: boolean = useSelector(
    (state) => state['app'].isAuthenticated
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(setCurrentUser(new UserDto()));
    dispatch(setAuthentication(false));
    localStorage.clear();
    history.push('/');
  };
  const signIn = () => {
    history.push('/coursesAuth');
  };
  const academy = () => {
    history.push('/courses');
  };
  const goToProfile = () => {
    history.push('/user-profile');
  };
  useEffect(() => {
    console.log('user' + user['fullname']);
    dispatch(setShowSingUp(false));
  }, []);

  return (
    <div >
      <Navbar expand="lg">
        <Navbar.Brand href="/courses/">
          <img
            alt=""
            src={logo}
            className="d-inline-block align-top navbarBrandImage"
          />
        </Navbar.Brand>
        <Navbar.Text className="d-block d-sm-none">
          <a href="https://www.facebook.com/ASSISTAsia/" target="_blank">
            <i className="fab fa-facebook-f navheadertextMobile"></i>
          </a>
          <a href="https://twitter.com/ASSISTAsia" target="_blank">
            <i className="fab fa-twitter navheadertextMobile"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UCO_GBXjQnQ-1BDDkLTiM9nA"
            target="_blank"
          >
            <i className="fab fa-youtube navheadertextMobile"></i>
          </a>
        </Navbar.Text>
        {/* <Navbar.Text className="navheaderForApp p-2">
          Assist Academy
        </Navbar.Text> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-start mobnav"
        >
          <ul className="navbar-nav mr-auto ml-auto">
            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://assistasia.org/about-us/"
              >
                About
              </a>
            </li>
            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://assistasia.org/development-solutions/"
              >
                Development Solutions
              </a>
            </li>
            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://assistasia.org/social-actions/"
              >
                Social Actions
              </a>
            </li>

            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://assistasia.org/projects/"
              >
                Projects
              </a>
            </li>

            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://creativelab.assistasia.org/"
              >
                Creative Lab
              </a>
            </li>
            <li className="nav-item headnavborder"></li>
            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://assistasia.org/news/"
              >
                Newsroom
              </a>
            </li>

            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://assistasia.org/work-with-us/"
              >
                Work with Us
              </a>
            </li>

            <li className="nav-item ">
              <a
                className="nav-link headbannertext"
                href="https://assistasia.org/youth-involvement/"
              >
                Youth Involvement
              </a>
            </li>

            <li
              className="nav-item btnSignin d-block d-sm-none"
              onClick={signIn}
            >
              {!isAuthenticated && <span className="">Sign In</span>}
            </li>
            <li
              className="nav-item btnSignin d-block d-sm-none"
              onClick={logout}
            >
              {isAuthenticated && <span className="">Logout</span>}
            </li>
            <li className="nav-item mobileMenu">
              <button className="btnheadApp" onClick={academy}>
                Academy
              </button>
            </li>
            <li className="nav-item headnavborder"></li>
            <li className="nav-item mobileMenu">
              <a
                className="nav-link"
                href="https://www.facebook.com/ASSISTAsia/"
                target="_blank"
              >
                <i className="fab fa-facebook-f headbannertext"></i>
              </a>
            </li>
            <li className="nav-item mobileMenu ">
              <a
                className="nav-link"
                href="https://twitter.com/ASSISTAsia"
                target="_blank"
              >
                <i className="fab fa-twitter headbannertext"></i>
              </a>
            </li>
            <li className="nav-item mobileMenu">
              <a
                className="nav-link "
                href="https://www.youtube.com/channel/UCO_GBXjQnQ-1BDDkLTiM9nA"
                target="_blank"
              >
                <i className="fab fa-youtube headbannertext"></i>
              </a>
            </li>

            <li className="nav-item headbannertext dropleft">
              {isAuthenticated && (
                <span className="mobileMenu">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <img src={userGreen}></img> */}
                    <span className="userName ">
                      <i
                        className="fas fa-user-circle usericon"
                        style={{ color: 'green' }}
                      ></i>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu dropdrownLeft"
                    aria-labelledby="navbarDropdown"
                  >
                    {/* <span className="dropdown-item   userName userprofile text-center">
                      {user['fullName']}
                    </span>
                    <div className="dropdown-divider"></div> */}
                    <button type="button" className="userprofile" onClick={goToProfile}>
                      <i className="fas fa-file-alt"></i>&nbsp;User Profile
                    </button>
                    <div className="dropdown-divider"></div>
                    <span className="dropdown-item text-center">
                      <button className="btnLogout" onClick={logout}>
                        Logout
                      </button>
                    </span>
                  </div>
                </span>
              )}
              {!isAuthenticated && (
                <span className=" headbannertext navheaderguest mobileMenu">
                  <button className="btnheadApp" onClick={signIn}>
                    Sign In
                  </button>
                </span>
              )}
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>

     
    </div>
  );
};

export default IsAuthenticated(HeadBanner, true);
