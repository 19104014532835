import React, { useState } from 'react';
import { IsAuthenticated } from '../helpers/withAuthentication';
import './certificate.css';
//import certborder from '../assets/images/certborder.png';
// import out2 from '../assets/images/out2.png';
//import bottomright_green from '../assets/images/bottomright_green.png';
//import greenbar from '../assets/images/greenbar.png';
import img_assist from '../assets/images/img_assist.png';
import certdelloitte from '../assets/images/certdelloitte.png';
import { Button } from 'react-bootstrap';
import Pdf from 'react-to-pdf';
import { useRef } from 'react';
import { UserDto } from '../models/UserDto';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TestDto } from '../models/TestDto';
import { setIsLoading } from '../redux/actions/app';
import app from '../redux/reducers/app';
import { TestService } from '../services/testService';
interface ICertificate {
  testId: any;
  testScore: any;
}
const Certificate: React.FC<ICertificate> = (props) => {
  const ref = useRef();
  const options = {
    orientation: 'landscape',
  };
  const user: UserDto = JSON.parse(localStorage.getItem('user'));
  const [course, setCourseName] = useState('0');

  const dispatch = useDispatch();
  const getTestData = async () => {
    dispatch(setIsLoading(true));
    const testApi: TestService = new TestService();
    const testData = new TestDto();
    testData.test = props.testId;
    const user: UserDto = JSON.parse(localStorage.getItem('user'));
    console.log(user);

    testData.user_email = user.email;

    testApi.getResult(testData).then((res) => {
      console.log(JSON.stringify(res));
      setCourseName(res.courseName); //res.courseName
      dispatch(setIsLoading(false));
    });
  };
  useEffect(() => {
    getTestData();
    console.log(JSON.stringify(app));
  }, []);
  useEffect(() => {
    console.log('props.testScore---' + props.testScore);
    console.log('props.testScore---' + props.testId);
  }, []);
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col text-right">
          <Pdf targetRef={ref} filename="certificate.pdf" options={options}>
            {({ toPdf }) => (
              <Button type="button" className="btnDownloadCert" onClick={toPdf}>
                <i className="fas fa-file-download"></i> Download
              </Button>
            )}
          </Pdf>
        </div>
      </div>
      <div className="container-fluid certDiv" ref={ref}>
        <div className="">
          <h1 className="row cetficateheader">Certificate of Completion</h1>
          <p className = "corseattendence">of attendance of course</p>
          <h3>{course}</h3>

          {/* <img src={certificate} alt="certificate"></img> */}
          {/* <div className="row lblattendance">
              <p>
                for Completion of <span className='courseName'> {course}</span> with <span className='courseScore'>{props.testScore}</span>
              </p>
            </div> */}
          <div className="row lblcustom">
            <p>Proudly Presented to</p>
          </div>
        </div>
        <div className="row particpantmane">
          <p>{user.fullName}</p>
        </div>
        <p className = "lblcontrols mx-5">
          For completing <strong>{course}</strong> with <strong>{props.testScore}% </strong> , via ASSIST Academy.
        </p>
       
        <div className=" row dvcontrols">
          <div className="some col-md-4">
            <div className="form-group">
              <div className="input-group">
                <input className="form-control" type="text" name="signature" />
              </div>
            </div>
            <label className = "lblcontrols">Signature</label>
          </div>
          <div className="some col col-md-3">
            <img src={img_assist} alt="delloitte_logo" className="logo"></img>
          </div>{' '}
          <div className="some col col-md-4">
            <div className="form-group  ">
              <div className="input-group">
                <input className="form-control" type="text" name="date" />
              </div>
            </div>
            <label className = "lblcontrols">Date</label>
          </div>
          
        </div>
 <span className = "row justify-content-center sponsor mx-5">Sponsors and Partners</span>
        <div className="row mt-2 justify-content-center dvlogo">
          
          <div className=" col col-md-5 dvlogo">
         
            <img
              src={certdelloitte}
              alt="delloitte_logo"
              className="imglogo"
            ></img>
         
            <img
              src={certdelloitte}
              alt="delloitte_logo"
              className="imglogo"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IsAuthenticated(Certificate, true);
