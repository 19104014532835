import { VidApi } from './api';
import { CommentDto } from '../models/CommentDto';

export class CommentService extends VidApi {
  public async addComment(data: CommentDto): Promise<any> {
    console.log('testData' + JSON.stringify(data));
    const res = await this.PostObjectAuth(
      'comment/auth/addComment',
      'Add new Comment',
      data
    );
    console.log('testRest' + JSON.stringify(res));
    return res;
  }

  public async getCommentsById(query:string): Promise<CommentDto[]> {
    const res = await this.Get(
      `comment/auth/getComment/${query}`,
      'Get Comments by Course'
    );
    //console.log(JSON.stringify(res));
    return res;
  }
}
