import React from 'react';
import { useParams } from 'react-router-dom';
import VideoComponent from '../components/videoComponent';
import { IsAuthenticated } from '../helpers/withAuthentication';
//import { setShowSingUp } from '../redux/actions/app';
const Video: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
      <VideoComponent id={id}></VideoComponent>
    </div>
  );
};

export default IsAuthenticated(Video, false);
