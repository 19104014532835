import { PartnerDto } from '../models/PartnerDto';
import { VidApi } from './api';

export class PartnerService extends VidApi {

    public async getPartner(): Promise<PartnerDto[]> {
        const res = await this.Get(`partner`, 'Get All Partners');
        console.log(JSON.stringify(res));
        return res;
      }

      public async addnewpartner(partnerdata: PartnerDto): Promise<any> {
        console.log('addnewpartner' + JSON.stringify(partnerdata));
        const result = await this.PostObjectAuth(
          'partner/auth/addnewpartner',
          'addNewPartner',
          partnerdata
        );
        console.log('------' + JSON.stringify(result));
        return result;
      }

      public async deletepartner(partnerdata: PartnerDto ): Promise<any> {
        console.log('deletepartner' + JSON.stringify(partnerdata.id));
        const result = await this.DeleteObjectAuth(
          'partner/auth/deletepartner',
          'deletePartner',
          partnerdata.id
        );
        console.log('------' + JSON.stringify(result));
        return result;
      }
      
}