import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './carouselCustom.css';
import CoursesTileMobile from '../components/courseTileMobile';
 import nextmob from "../assets/images/nextmob.png";
 import prevmob from "../assets/images/prevmob.png";

interface ICarousel {
  courseList: any;
  category: any;
}

const CarouselCustom: React.FC<ICarousel> = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    console.log(JSON.stringify(props.courseList));
    console.log(JSON.stringify(props.courseList[props.category]));
  }, []);
  return (
    <>
      <div>
        <Carousel
          controls={false}
          interval={null}
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
        >
          {props.courseList[props.category].map((course, kry) => (
            <Carousel.Item key={kry} interval={null}>
              <CoursesTileMobile course={course} isAuthenticated={true} />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="mb-4">
           <img src= {prevmob}  className="fas fa-less-than mr-2 countCourse" onClick={()=> setIndex(index -1)} hidden={index <1}></img>
          {/* <i className="fas fa-less-than mr-2 countCourse" onClick={()=> setIndex(index -1)} hidden={index <1}></i>{' '} */}
          <span className="countCourse"> {index + 1}</span>{' '}
          <span className="finalCount">
            / {props.courseList[props.category].length}
          </span>
          <img src = {nextmob}  className="fas fa-greater-than ml-2 countCourse" onClick={()=> setIndex(index +1)} hidden={index >= props.courseList[props.category].length -1}></img>
          {/* <i className="fas fa-greater-than ml-2 countCourse" onClick={()=> setIndex(index +1)} hidden={index >= props.courseList[props.category].length -1}></i>{' '}
      */}
        </div>
      </div>
    </>
  );
};
export default CarouselCustom;
