import { SponsorDto } from './SponsorDto';

export class CourseDto {
  public category: any;
  public headerCategory: any;
  public description: string;
  public id: string;
  public image: string;
  public localId: string;
  public name: string;
  public video: string;
  public sponsors: SponsorDto[];
  public tags: string[];

  constructor() {
    this.tags = [];
    this.sponsors = [];
    this.category = {};
    this.headerCategory = {};
  }
}
