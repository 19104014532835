import { CategoryDto } from '../../models/CategoryDto';
import { UserDto } from '../../models/UserDto';
import {
  SET_AUTHENTICATION,
  SET_IS_LOADING,
  SET_SHOW_SIGNUP,
  SET_NAV_OPEN,
  SET_CURRENT_USER,
  SET_CURRENT_COURSE_VIDEO,
  SET_TOAST_SHOW,
  SET_CATEGORY_MENU,
  SET_TEST_ID
} from '../actions/app';

export class AppStateModel {
  isAuthenticated: boolean;
  isLoading: boolean;
  setShowSignUpModal: boolean;
  navOpen: boolean;
  currentUser: UserDto;
  currentVideo: any;
  toastShow: boolean;
  categories: CategoryDto[];
  setShowSignUpSocialModal: boolean;
  testId:string;
  constructor() {
    this.isAuthenticated = false;
    this.isLoading = false;
    this.setShowSignUpModal = false;
    this.setShowSignUpSocialModal = false;
    this.navOpen = true;
    this.currentUser = new UserDto();
    this.currentVideo = 0;
    this.toastShow = false;
    this.categories = [];
    this.testId='';
  }
}

const getInitialState = (): AppStateModel => {
  const appStateModel = new AppStateModel();

  appStateModel.isLoading = false;
  appStateModel.isAuthenticated = false;
  appStateModel.setShowSignUpModal = false;
  appStateModel.navOpen = true;
  appStateModel.currentUser = new UserDto();
  appStateModel.currentVideo = 0;
  appStateModel.toastShow = false;
  appStateModel.categories = [];
  appStateModel.setShowSignUpSocialModal=false;
  appStateModel.testId='';
  return appStateModel;
};

const app = (state = getInitialState(), action: { type: any; value: any }) => {
  
  switch (action.type) {
    case SET_CATEGORY_MENU: {
    
      return { ...state, categories: action.value };
    }
    case SET_AUTHENTICATION: {
      return { ...state, isAuthenticated: action.value };
    }

    case SET_IS_LOADING: {
      return { ...state, isLoading: action.value };
    }
    case SET_SHOW_SIGNUP: {
      return { ...state, setShowSignUpModal: action.value };
    }
    case SET_NAV_OPEN: {
      return { ...state, navOpen: action.value };
    }
    case SET_CURRENT_USER: {
      return { ...state, currentUser: action.value };
    }
    case SET_CURRENT_COURSE_VIDEO: {
      return { ...state, currentVideo: action.value };
    }
    case SET_TOAST_SHOW: {
      
      return { ...state, toastShow: action.value };
    }
    case SET_TEST_ID: {
      
      return { ...state, toastShow: action.value };
    }
  }
  return state;
};

export default app;
