import React from 'react';
import { Button } from 'react-bootstrap';
import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Bar, ResponsiveContainer , Legend,
PieChart, Pie} from 'recharts';
import { useForm } from 'react-hook-form';
import { IsAuthenticated } from '../../helpers/withAuthentication';
import { UserDto } from '../../models/UserDto';
import './loginReport.css';

const LoginReport: React.FC = () => {
  const { register } = useForm<UserDto>();

  return (
    <form>
      <div className="container-fluid col-md-12">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h4>ASSIST ACADEMY- LOGIN REPORT</h4>
            </div>
            <div className="col-md-3 ">
              <a href="#" className="float-right home">
                Home <i className="fa fa-home" aria-hidden="true"></i>
              </a>
            </div>
          </div>

          <div className="row rowmargin">
            <div className="col-md-7">
              <Button className="btnheader">Today</Button>
              <Button className="btnheader">Weekly</Button>
              <Button className="btnheader">Monthly</Button>
              <Button className="btnheader">Yearly</Button>
            </div>
            <div className="col-md-5">
              <div className="">
                <input
                  type="date"
                  id="fromdate"
                  name="fromdate"
                  placeholder="Start Time"
                  className="form-group inputdate"
                />
                <input
                  type="date"
                  id="todate"
                  name="todate"
                  placeholder="End Time"
                  className="form-group inputdate"
                />
              </div>
            </div>
          </div>

          <div className="row rowmargin">
            <div className="col-md-6">
            <ResponsiveContainer height={300}>
            <BarChart width={730} height={250} >
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="name" />
  <YAxis />
  <Tooltip />
  <Legend />
  <Bar dataKey="pv" fill="#8884d8" />
  <Bar dataKey="uv" fill="#82ca9d" />
</BarChart>
</ResponsiveContainer>
            </div>
            <div className="col-md-6">
            <ResponsiveContainer>
            <PieChart width={730} height={250}>
  <Pie  dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
  <Pie  dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label />
</PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="row rowmargin">
            <Button className=" btnstats">LOGIN</Button>
            <Button className=" btnstats">VIDEOS</Button>
            <Button className=" btnstats">CERTIFICATIONS</Button>
            <Button className=" btnstats">PAGE VISITED</Button>
          </div>

          <div className="row rowmargin ">
            <div className="form-group  col-md-5">
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control input-text fa fa-search"
                  name="name"
                  placeholder="Search Name /Email Id/"
                  ref={register({ required: true })}
                ></input>
              </div>
            </div>

            <div className="form-group col-md-5">
              <div className="input-group">
                <select
                  className="form-control fa input-text"
                  name="video"
                  ref={register({ required: true })}
                >
                  <option>Dummy Video 1</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row rowmargin">
            <div className="col-md-8">
              <div className="form-check ">
                  <div className = "radiobtnvideo">
                <input
                  className="form-check-input radio "
                  type="radio"
                  name="radio"
                  id="sponsorradio"
                  value="option1"
                  checked
                />
                <label className="form-check-label lblradio" htmlFor="sponsorradio">
                  All
                </label>
                </div>
                <div className = "radiobtnvideo">
                <input
                  className="form-check-input radio "
                  type="radio"
                  name="radio"
                  id="sponsorradio"
                  value="option1"
                  checked
                />
                <label className="form-check-label lblradio" htmlFor="sponsorradio">
                  Watched
                </label>
                </div>
                <div className = "radiobtnvideo">
                <input
                  className="form-check-input radio "
                  type="radio"
                  name="radio"
                  id="sponsorradio"
                  value="option1"
                  checked
                />
                <label className="form-check-label lblradio" htmlFor="sponsorradio">
                  In Progress
                </label>
                </div>
                <div className = "radiobtnvideo">
                <input
                  className="form-check-input radio "
                  type="radio"
                  name="radio"
                  id="sponsorradio"
                  value="option1"
                  checked
                />
                <label className="form-check-label lblradio" htmlFor="sponsorradio">
                  Test Completed
                </label>
                </div>
               </div>
            </div>
            <div className="col-md-4">
              <Button className = "faicons"><i className="fa fa-print" aria-hidden="true"></i></Button>
              <Button className = "faicons"><i className="fa fa-download" aria-hidden="true"></i></Button>
              <Button className = "faicons"><i className="fa fa-plus" aria-hidden="true"></i></Button>
            </div>
          </div>

          <div className = "rowmargin">
            <table className="table">
              <thead className="tableHeader">
                <tr>
                  <th scope="col" className="tableHeaderCol text-uppercase">Sr.No</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Name</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Email Id</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Video / Video Series</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Status</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Date and Time</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Certification</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Feedback/ Rating</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </form>
  );
};

export default IsAuthenticated(LoginReport, false);
