export class UserDto{

    public fullName: string;
    public email: string;
    public id: string;
    public password: string;
    public mobileNumber: string;
    public gender: string;
    public age: number;
    public profession:string;
    public location:string;
    public role:string;
    public providerId:string;
    public provider:string;
    public imageUrl:string;
    public signUpComplete:boolean;
    public jwt?: string;
    
 }
