//import { useDispatch } from 'react-redux';
import { CategoryDto } from '../../models/CategoryDto';
import { UserDto } from '../../models/UserDto';
// import { AuthService } from '../../services/authService';
import { CourseService } from '../../services/courseService';
//import { CourseService } from '../../services/courseService';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_SHOW_SIGNUP = 'SET_SHOW_SIGNUP';
export const SET_NAV_OPEN = 'SET_NAV_OPEN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_COURSE_VIDEO = 'SET_CURRENT_COURSE_VIDEO';
export const SET_TOAST_SHOW = 'SET_TOAST_SHOW';
export const SET_CATEGORY_MENU = 'SET_CATEGORY_MENU';
export const SET_SHOW_SIGNUP_SOCIAL = 'SET_SHOW_SIGNUP_SOCIAL';
export const SET_TEST_ID = 'SET_TEST_ID';

export const setTestId = (value: string) => async (dispatch: any) => {
  return dispatch({
    type: SET_AUTHENTICATION,
    value: value,
  });
};
export const setAuthentication = (value: boolean) => async (dispatch: any) => {
  return dispatch({
    type: SET_AUTHENTICATION,
    value: value,
  });
};
export const setIsLoading = (value: boolean) => async (dispatch: any) => {
  return dispatch({
    type: SET_IS_LOADING,
    value: value,
  });
};
export const setToastShow = (value: boolean) => async (dispatch: any) => {
  console.log("herer2");
  return dispatch({
    type: SET_TOAST_SHOW,
    value: value,
  });
};
export const setShowSingUp = (value: boolean) => async (dispatch: any) => {
  return dispatch({
    type: SET_SHOW_SIGNUP,
    value: value,
  });
};

export const setNavOpen = (value: boolean) => async (dispatch: any) => {
  return dispatch({
    type: SET_NAV_OPEN,
    value: value,
  });
};

export const setCurrentUser = (value: UserDto) => async (dispatch: any) => {
  return dispatch({
    type: SET_CURRENT_USER,
    value: value,
  });
};
export const setCurrentCourseVideo = (value: any) => async (dispatch: any) => {
  localStorage.set('video',value);
  return dispatch({
    type: SET_CURRENT_COURSE_VIDEO,
    value: value,
  });
};
export const setCategories = (value: CategoryDto[]) => (dispatch: any) => {
 // console.log('cates;;;'+JSON.stringify(value));
  return dispatch({
    type: SET_CATEGORY_MENU,
    value: value,
  });
};
export const setShowSingUpSocial = (value: boolean) => async (dispatch: any) => {
  return dispatch({
    type: SET_SHOW_SIGNUP_SOCIAL,
    value: value,
  });
};

export const getCategories = () => async (dispatch: any) => {
  console.log('asdasd');
  const Courseapi: CourseService = new CourseService();
  const cates = await Courseapi.getCategory();
  //console.log('cates;######'+cates);
  dispatch(setCategories(cates));
  // localStorage.setItem('authentication-token', token['jwt']);
};
//export const userLogin = (data: UserDto) => async () => {
  // const dispatch = useDispatch();
  // const api: AuthService = new AuthService();
  // //const Courseapi: CourseService = new CourseService();
  // const user: UserDto = await api.addnewuser(data);
  // console.log('token-=-=' + user);
  // dispatch(setAuthentication(true));
  // dispatch(setCurrentUser(user));
  // localStorage.setItem('authentication-token', user['jwt']);
//};
