import React, { useState } from 'react'; /* , { useEffect, useState } */
/* import { Button, Form } from 'react-bootstrap'; */
import { IsAuthenticated } from '../helpers/withAuthentication';
import './userProfile.css';
import wavingHand from '../assets/images/waving-hand.svg';
import ProfileInformation from '../components/profileInformation';


const UserProfile: React.FC = () => {
  const [showPersonalInfo, setShowPersonalInfo] = useState(true);
  const [showCourseHistory, setShowCourseHistory] = useState(false);
  const [showTestHistory, setShowTestHistory] = useState(false);
  const [showCertification, setShowCertification] = useState(false);
  let userDetails = null;
  userDetails = JSON.parse(localStorage.getItem('user'));

  function setTabDisplay(tab) {
    if (tab === 'showPersonalInfo' || (tab !== 'showPersonalInfo' && showPersonalInfo === true)) {
      setShowPersonalInfo(!showPersonalInfo);
    }
    if (tab === 'showCourseHistory' || (tab !== 'showCourseHistory' && showCourseHistory === true)) {
      setShowCourseHistory(!showCourseHistory);
    }
    if (tab === 'showTestHistory' || (tab !== 'showTestHistory' && showTestHistory === true)) {
      setShowTestHistory(!showTestHistory);
    }
    if (tab === 'showCertification' || (tab !== 'showCertification' && showCertification === true)) {
      setShowCertification(!showCertification);
    }
  }

  return (
    <div className="container-fluid m-0 p-0">
      <section className="userProfileBanner">
        <div className="container">
          <div className="profileBannerContent">
            <div>
              <h1 className="text-left hello">Hello,</h1>
              <h2 className="text-left profileUserName">
                {userDetails.fullName} <img src={wavingHand} className="wavingHandImg" alt="waving hand"></img>
              </h2>
            </div>
            <div className="tabsSection">
              <span className={`${showPersonalInfo ? 'tabSelected' : ''}`} onClick={() => setTabDisplay('showPersonalInfo')}>Personal Information</span>
              <span className={`${showCourseHistory ? 'tabSelected' : ''}`} onClick={() => setTabDisplay('showCourseHistory')}>Course History</span>
              <span className={`${showTestHistory ? 'tabSelected' : ''}`} onClick={() => setTabDisplay('showTestHistory')}>Test History</span>
              <span className={`${showCertification ? 'tabSelected' : ''}`} onClick={() => setTabDisplay('showCertification')}>Certification</span>
            </div>
          </div>
        </div>
      </section>
      <section className="profileTabs">
        <ProfileInformation showPersonalInfo={showPersonalInfo} userDetails={userDetails}/>
      </section>
    </div>
  );
};

export default IsAuthenticated(UserProfile, true);