import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IsAuthenticated } from '../../helpers/withAuthentication';
import { CourseDto } from '../../models/CourseDto';
import { CategoryDto } from '../../models/CategoryDto';
//import { PartnerDto } from '../../models/PartnerDto';
import { SponsorDto } from '../../models/SponsorDto';
import './uploadCourse.css';
import { useDispatch } from 'react-redux';
import { setIsLoading, setToastShow } from '../../redux/actions/app';
import { CourseService } from '../../services/courseService';
import { CategoryModal } from '../../admin_modals/categoryModal';
import ToastForAll from '../../components/Toast';
import { Util } from '../../helpers/util';
import { SponsorService } from '../../services/sponsorService';
import { SubCategoryModal } from '../../admin_modals/subCategoryModal';
import { Sponsor } from '../../admin_modals/sponsor';
import { useParams } from 'react-router-dom';
//import btnUpload from "../../assets/images/btn-upload-pdf.png";
// import edit from "../../assets/images/edit.png";
// import deletebtn from "../../assets/images/deletebtn.png";

const UploadCourse: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isEdit, setIsEdit] = useState(false);
  const { register, handleSubmit, setValue } = useForm<CourseDto>();
  const [tags, settags] = useState([]);
  const [currentTag, setCurrentTag] = useState('');
  const [show, setShow] = useState(false);
  const [showsubcategory, setShowSubCategory] = useState(false);
  const [showSponsor, setShowSponsor] = useState(false);
  //const [showPartner, setShowPartner] = useState(false);
  const [course, setCourse] = useState(new CourseDto());
  const [categoryList, setCategoryList] = useState(new Array<CategoryDto>());
  const [subCategoryList, setSubCategoryList] = useState(new CategoryDto());
  // const [partnerList, setPartnerList] = useState(new Array<PartnerDto>());
  const [sponsorList, setSponsorList] = useState(new Array<SponsorDto>());
  const [sponsors, setSponsors] = useState(new Array<SponsorDto>());
  const [toastMsg, setToastMsg] = useState(' ');

  const dispatch = useDispatch();

  const getCategories = async () => {
    const courseApi: CourseService = new CourseService();
    courseApi.getCategory().then((res) => {
      console.log('asdasdasdasd', JSON.stringify(res));
      setCategoryList(res);
      setSubCategoryList(res[0]);
      // if(course !=null)
      // handleCatChange(course.headerCategory.id);
    });
    console.log(JSON.stringify(categoryList));
  };
  const getCourse = async () => {
    const courseApi: CourseService = new CourseService();
    courseApi.getCoursesVideo(id).then((res) => {
      setCourse(res);
      setValuesForEdit(res);
      setSponsors(res.sponsors);
      // console.log('Vlaiee', JSON.stringify(res));
      return res;
    });
    console.log('Vlaiee', JSON.stringify(sponsors));
    return new CourseDto();
  };

  const checkSponsors = (id): boolean => {
    if (sponsors != null && sponsors.length > 0) {
      const val = sponsors.find((x) => x.id == id);
      if (val) return true;
      else return false;
    }
  };

  const getSponsors = async () => {
    const sponsorApi: SponsorService = new SponsorService();

    sponsorApi.getSponsor().then((res) => {
      setSponsorList(res);
      // console.log('getSponsor' + JSON.stringify(sp));
      // setValue('sponsors', sp);
    });
  };

  useEffect(() => {
    getCategories().then(() => {
      getSponsors();
      if (id) {
        setIsEdit(true);
        getCourse();
      }
      console.log(isEdit);
    });
    //getPartners();
  }, []);

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      setValuesForEdit(course);
      setIsLoading(false);
    }
    //getPartners();
  }, [course]);

  const setValuesForEdit = (courseData: CourseDto) => {
    console.log('courseData.cates' + JSON.stringify(categoryList));
    console.log('courseData.headerCategory.id' + courseData.headerCategory.id);
    console.log('courseData.category.id' + courseData.category.id);
    setValue('video', courseData.video);
    setValue('description', courseData.description);
    setValue('name', courseData.name);
    setValue('categoryheader', courseData.headerCategory.id);

    handleCatChange(courseData.headerCategory.id);
    // setSubCategoryList(categoryList[0]);
    setValue('category', courseData.category.id);

    settags(courseData.tags);
  };

  const onSubmit = handleSubmit(async (data, e) => {
    if (isEdit == false) {
      dispatch(setIsLoading(true));
      setToastMsg('New Course Added');
      const vid: string = Util.getVideoId(data.video);
      data.image = Util.getImage(vid);
      data.tags = tags;
      console.log('data subnit==***' + JSON.stringify(data));
      const courseservice: CourseService = new CourseService();
      const token = await courseservice.addnewcourse(data);
      console.log('22' + token);
      dispatch(setIsLoading(false));
      dispatch(setToastShow(true));
      setSubCategoryList(new CategoryDto());
      settags([]);
      e.target.reset();
    } 
    else if (isEdit == true) {
      dispatch(setIsLoading(true));
      setToastMsg('Course Updated');
      const vid: string = Util.getVideoId(data.video);
      data.image = Util.getImage(vid);
      data.id=id;
      data.tags = tags;
      console.log('data Ediur==***' + JSON.stringify(data));
      const courseservice: CourseService = new CourseService();
      const token = await courseservice.updateCourse(data);
      console.log('22' + token);
      dispatch(setIsLoading(false));
      dispatch(setToastShow(true));
      window.location.reload();
    }
  });

  const handleChangesub = (e) => {
    console.log(e.target.value);
    const value = subCategoryList.subcategories.filter(function (item) {
      return item.id == e.target.value;
    });
    console.log(value[0].id);
  };

  const handleCatgoryChange = (e) => {
    console.log(e.target.value);
    if (e.target.value != -1) {
      handleCatChange(e.target.value);
    }
  };

  const handleCatChange = (id) => {
    if (id != -1 && categoryList != null && categoryList.length > 0) {
      const value: CategoryDto[] = categoryList.filter(function (item) {
        return item.id == id;
      });
      setSubCategoryList(value[0]);
    }
  };

  const handleChangesponsor = (e) => {
    console.log(e.target.selectedOptions.id);
  };
  const handletaginputChanges = (e) => {
    setCurrentTag(e.target.value);
  };

  const addTagToArray = () => {
    const listtags = currentTag.split(',');

    console.log('-------' + currentTag);
    console.log('listtags-------' + listtags);
    settags([...tags, ...listtags]);
  };

  const removeTag = (tag) => {
    console.log('in Romve' + tag);
    let tagarray = tags;
    console.log('' + tagarray);
    tagarray = tagarray.filter((item) => item !== tag);

    settags(tagarray);
  };

  const handleShow = () => {
    setToastMsg('New Category Added');
    setShow(true);
  };
  const handleShowsubcategory = () => {
    setToastMsg('New Subcategory Added');
    setShowSubCategory(true);
  };
  const handleShowsponsor = () => {
    setToastMsg('New Sponsor Added');
    setShowSponsor(true);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="container-fluid col-md-12">
          <div className="container">
            <div className = "row">
            {/* <div className="col text-left">
            <img src = {deletebtn} alt = "delete" className = "btnedit" ></img>
                <img src = {edit} alt = "edit" className = "btnedit" ></img>
               
                </div> */}
            </div>
            <div className="row ">
              <div className="form-group  col">
                <div className="lbl">
                  <label className="fieldLabels">
                    Video URL <span className="color">* </span>
                  </label>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control input-text"
                    name="video"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>

        
            <div className="row ">
              <div className="form-group  col">
                <div className="lbl">
                  <label className="fieldLabels">
                    Video Name <span className="color">* </span>
                  </label>
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control input-text"
                    name="name"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="form-group col-md-6">
                <div className="lbl">
                  <label className="fieldLabels">
                  Video Category <span className="color">* </span>
                  </label>
                </div>
                <div className="input-group">
                  <select
                    className="form-control input-text"
                    name="categoryheader"
                    onChange={handleCatgoryChange}
                    ref={register({ required: true })}
                  >
                    {/* <option value={-1}>Select Category</option> */}
                    {categoryList.map(function (category, key) {
                      return (
                        <option key={key} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="btn btn-link"
                    onClick={handleShow}
                    type="button"
                  >
                    <i className="mx-2 fa fa-plus "></i>
                  </button>
                </div>
              </div>
              <div className="form-group col-md-6">
                <div className="lbl">
                  <label className="fieldLabels">
                    Course Subcategory <span className="color">* </span>
                  </label>
                </div>
                <div className="input-group">
                  <select
                    className="form-control input-text"
                    name="category"
                   
                    onChange={handleChangesub}
                    ref={register({ required: true })}
                  >
                    {subCategoryList.subcategories.map(function (
                      category,
                      key
                    ) {
                      return (
                        <option key={key} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="btn btn-link"
                    onClick={handleShowsubcategory}
                    type="button"
                  >
                    <i className="mx-2 fa fa-plus "></i>
                  </button>
                </div>
              </div>
            </div>


<div className = "row">
            <div className="col-md-7 form-group borders">
              <div className="lbl">
                <label className="fieldLabels">
                  Write a description here <span className="color">* </span>
                </label>
              </div>
              {/* <div className="btnuploaddoc">
                <button type="button" className="btnvideo  btn btn-primary">
                  <i className="fa fa-upload" aria-hidden="true"></i>Upload
                  PDF/DOC
                </button>
              </div> */}
               
              <textarea
                className=" form-control input-text descriptionTextarea"
                name="description"
                ref={register({ required: true })}
                rows={6}
              ></textarea>
            </div>

            <div className=" col-md-5  mt-7">
              <div className=" col-md-12">
                <div className="lbl">
                  <label className="fieldLabels">
                    Tags<span className="color">* </span>
                  </label>
                </div>
                <div className="form-group input-group ">
                  <input
                    type="text"
                    className="form-control input-text"
                    onChange={handletaginputChanges}
                    name="tags"
                  ></input>
                  <button
                    className="btn btn-link"
                    onClick={addTagToArray}
                    type="button"
                  >
                    <i className="mx-2 fa fa-plus "></i>
                  </button>
                </div>
              </div>
<div className = "row col-md-12 tagsection">
              {tags.map(function (tag, index) {
                return (
                  <button type="button" className="btn btn-link tagsName" key={index}>
                    {tag}
                    <i
                      className="fas fa-times mx-2"
                      onClick={() => removeTag(tag)}
                    ></i>
                  </button>
                );
              })}
            </div>
            </div>
            </div>

            <div className="row">
              <div className="selectPartner col-md-12">
                <div className="lbl">
                  <label className="fieldLabels">
                    Select Partners <span className="color">* </span>
                  </label>
                </div>
                <div className="input-group">
                  <select
                    size={sponsorList.length}
                    className="form-select form-control input-text shadow-none "
                    multiple
                    name="sponsors"
                    onChange={handleChangesponsor}
                    ref={register({ required: true })}
                  >
                    {sponsorList.map(function (sponsor, key) {
                      return (
                        <option
                          key={key}
                          className="multipleSelect"
                          value={sponsor.id}
                          selected={checkSponsors(sponsor.id)}
                        >
                          {sponsor.name}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="btn btn-link"
                    onClick={handleShowsponsor}
                    type="button"
                  >
                    <i className="mx-2 fa fa-plus "></i>
                  </button>
                </div>
              </div>
            </div>

         
            <div className="row">
              <div className="col-md-4"></div>

              <Button
                type="reset"
                id="reset"
                className="btn col-md-2 btnsubmit mx-1"
              >Reset
                </Button>
              <Button
                id="submit"
                className="btn btnsubmit col-md-2 mx-1"
                type="Submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
      <ToastForAll header="" msg={toastMsg}></ToastForAll>
      <CategoryModal show={show}></CategoryModal>
      <SubCategoryModal show={showsubcategory}></SubCategoryModal>
      <Sponsor show={showSponsor}></Sponsor>
      {/* <Partner show={showPartner}></Partner> */}
    </div>
  );
};

export default IsAuthenticated(UploadCourse, false);
