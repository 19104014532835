import { SubCategoryDto } from "./SubCategoryDto";

export class CategoryDto {
  public description: string;
  public id: any;
  public localId: string;
  public name: string;
  public subcategories: SubCategoryDto[];

  constructor(){
    this.subcategories =[];
  }
}
