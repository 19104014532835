import { CourseDto } from '../models/CourseDto';
import { CategoryDto } from '../models/CategoryDto';
import { VidApi } from './api';
import { setCategories } from '../redux/actions/app';
import { VideoLogDto } from '../models/VideoLogDto';
import { SubCategoryDto } from '../models/SubCategoryDto';


export class CourseService extends VidApi {

  public async getCourses(id:any): Promise<CourseDto[]> {
    const res = await this.Get(`courses/${id}`, 'Get All Courses');
    //console.log(JSON.stringify(res));
    return res;
  }
  public async getCoursesByCat(): Promise<CourseDto[]> {
    const res = await this.Get(
      'courses/auth/category',
      'Get All Courses By Auth'
    );
    console.log('getCoursesByCat'+JSON.stringify(res));
    return res;
  }
  public async getCoursesByCatSearch(query:string): Promise<CourseDto[]> {
    const res = await this.Get(
      `courses/search/${query}`,
      'Get All Courses By Search'
    );
    //console.log(JSON.stringify(res));
    return res;
  }
  public async getCoursesByCatFilter(query:string): Promise<CourseDto[]> {
    const res = await this.Get(
      `courses/filter/${query}`,
      'Get All Courses By Filter'
    );
    //console.log(JSON.stringify(res));
    return res;
  }
  public async getCoursesVideo(id: any): Promise<CourseDto> {
    
    const res = await this.Get(`courses/auth/${id}`, 'Get All Courses');
   
    console.log("asdasd"+JSON.stringify(res));
    return res;
  }

  public async getCategory(): Promise<CategoryDto[]> {
    const res = await this.Get(`category`, 'Get All Categories');
    setCategories(res);
        return res;
  }
  public async addnewcourse(coursedata: CourseDto): Promise<any> {
   // console.log('addcourse' + JSON.stringify(coursedata));
    const result = await this.PostObjectAuth(
      'courses/auth/addnewcourse',
      'addNewCourse',
      coursedata
    );
    // console.log('------' + JSON.stringify(result));
    return result;
  }
  public async updateCourse(coursedata: CourseDto): Promise<any> {
    // console.log('addcourse' + JSON.stringify(coursedata));
     const result = await this.PutbOjectAuth(
       'courses/auth/updateCourse',
       'addNewCourse',
       coursedata
     );
     // console.log('------' + JSON.stringify(result));
     return result;
   }

  public async addnewcategory(categorydata: CategoryDto): Promise<any> {
    //console.log('addnewcategory' + JSON.stringify(categorydata));
    const result = await this.PostObjectAuth(
      'category/auth/addnewcategoryheader',
      'addNewCategoryHeader',
      categorydata
    );
   // console.log('------' + JSON.stringify(result));
    return result;
  }
  
  public async addnewsubcategory(subcategorydata: SubCategoryDto): Promise<any> {
    //console.log('addnewcategory' + JSON.stringify(categorydata));
    const result = await this.PostObjectAuth(
      'category/auth/addnewcategory',
      'addNewCategory',
      subcategorydata
    );
   // console.log('------' + JSON.stringify(result));
    return result;
  }
  public async deleteCourse(id:any): Promise<CourseDto[]> {
    const res = await this.Delete(
      `courses/auth/${id}`,
      'Delete Course'
    );
    //console.log(JSON.stringify(res));
    return res;
  }

  public async addVideoLogs(videoLogEntry: VideoLogDto): Promise<any> {
    //console.log('addnewcategory' + JSON.stringify(categorydata));
    const result = await this.PostObjectAuth(
      'courses/auth/addvideologs',
      'addNewCategory',
      videoLogEntry
    );
   // console.log('------' + JSON.stringify(result));
    return result;
  }
}
