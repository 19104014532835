import React, { useEffect, useState } from 'react';
import { IsAuthenticated } from '../helpers/withAuthentication';
import './profileInformation.css';
import { Button } from 'react-bootstrap';
import { AuthService } from '../services/authService';
import { useForm } from 'react-hook-form';
import { UserDto } from '../models/UserDto';
import _ from 'lodash/fp';
import {
  setCurrentUser,
  setIsLoading,
  setToastShow,
} from '../redux/actions/app';
import { useDispatch } from 'react-redux';
import ToastForAll from './Toast';

interface profileInfo {
  showPersonalInfo: boolean;
  userDetails: any;
}

const ProfileInformation: React.FC<profileInfo> = (props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, errors } = useForm<UserDto>();
  const [toastMsg, setToastMsg] = useState(' ');
  
  let userdetls= null;
  userdetls =  JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    setValue('fullName', userdetls.fullName);
    setValue('email', userdetls.email);
    setValue('location', userdetls.location);
    setValue('profession', userdetls.profession);
    setValue('mobileNumber', userdetls.mobileNumber);
    setValue('age', userdetls.age);
    setValue('gender', userdetls.gender);
    setValue('jwt', userdetls.jwt);
   // setValue('role', userdetls.role);
   setValue('provider', userdetls.provider);
    setValue('providerId', userdetls.providerId);
   // setValue('signUpComplete', userdetls.signUpComplete);
    setValue('imageUrl', userdetls.imageUrl);
  //  setValue('password', userdetls.password);
  }, []);

  const obj = {
    array: [],
  };
  for (let l = 15; l < 100; l++) {
    obj.array[l] = l + 1;
  }
  const optionItems = obj.array.map((item) => (
    <option key={item}>{item}</option>
  ));

  const onSubmit = handleSubmit(async (data) => {
    console.log('header data before: ' + JSON.stringify(data));
    const authService: AuthService = new AuthService();
    
    dispatch(setIsLoading(true));
    setToastMsg('USer Profile Updated');
   // const user: UserDto = await authService.connectSocialComplete(data);
   authService.userprofile(data).then((result) => {
    
    dispatch(setCurrentUser(result));
    dispatch(setIsLoading(false));
    dispatch(setToastShow(true));
   });
  });
  return (
    <div>
      <form
        className={props.showPersonalInfo === false ? 'd-none' : ''}
        onSubmit={onSubmit}
      >
        {/* <input
          type="hidden"
          name="imageUrl"
          ref={register({ required: false })}
        />
        <input
          type="hidden"
          name="password"
          ref={register({ required: false })}
        /> */}
        {/* <input type="hidden" name="role" ref={register({ required: false })} /> */}
     <input
          type="hidden"
          name="provider"
          ref={register({ required: false })}
        />
        <input
          type="hidden"
          name="providerId"
          ref={register({ required: false })}
        />
         {/* <input
          type="hidden"
          name="signUpComplete"
          ref={register({ required: false })}
        />  */}
        <div className="container">
          <div className="row infoRow">
            <div className="personalInfoCard">
              <h4 className="cardTitle m-0 text-left">Personal Information</h4>
              <p className="cardSubtitle text-left">
                Update your personal informaiton
              </p>
              <div className="form-group row profileFormGroup">
                <label className="inputLabels">Full Name</label>
                <span className="color">*</span>
                {_.get('fullName.type', errors) === 'pattern' && (
                  <p className="validation">Alphabetical characters only</p>
                )}
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    name="fullName"
                    ref={register({
                      required: true,
                      maxLength: 20,
                      pattern: /^[a-zA-Z ]*$/,
                    })}
                  />
                </div>
              </div>
              <div className="form-group row profileFormGroup">
                <div className="input-group">
                  <label className="inputLabels">Your Profession</label>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      name="profession"
                      ref={register({ pattern: /^[a-zA-Z]+$/ })}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row profileFormGroup">
                <div className="input-group">
                  <label className="inputLabels">Age</label>
                  <select
                    className="form-control inputstyle"
                    name="age"
                    ref={register({ required: false })}
                  >
                    <option>Age</option>
                    {optionItems}
                  </select>
                </div>
              </div>
              <div className="form-group row profileFormGroup mb-0">
                <div className="input-group">
                  <label className="inputLabels">Gender</label>
                  <select
                    className="form-control inputstyle"
                    name="gender"
                    ref={register({ required: false })}
                  >
                    <option>Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="contactInfoCard">
              <h4 className="cardTitle m-0 text-left">Contact Information</h4>
              <p className="cardSubtitle text-left">
                Update your personal informaiton
              </p>
              <div className="form-group row profileFormGroup">
                <label className="inputLabels">Mobile Number</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    name="mobileNumber"
                    ref={register({ required: true, maxLength: 12 })}
                  />
                </div>
              </div>
              <div className="form-group row profileFormGroup">
                <label className="inputLabels">Email ID</label>
                <span className="color">*</span>
                <div className="input-group">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className="form-group row profileFormGroup">
                <label className="inputLabels">Location</label>
                <span className="color">*</span>
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    name="location"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row profileInfoButtonRow mb-5">
            <Button
              id="cancel"
              className="btnreset col-6 col-md-3 col-lg-2 mx-1 my-2 my-md-0"
            >
              Cancel
            </Button>
            <Button
              id="submit"
              className="btn btnsubmit col-6 col-md-3 col-lg-2 mx-1 my-2 my-md-0"
              type="Submit"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </form>
      <ToastForAll header="" msg={toastMsg}></ToastForAll>
    </div>
  );
};

export default IsAuthenticated(ProfileInformation, true);
