import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './comments.css';
import avtar from '../assets/images/ic_user.png';
import { useEffect } from 'react';
import { Util } from '../helpers/util';
import { useState } from 'react';

interface IComments {
  username: string;
  comment: string;
  date: Date;
}
const Comments: React.FC<IComments> = (props) => {
  const [timeAgo, setTimeAgo] = useState('');
  useEffect(() => {
    setTimeAgo(Util.getTimeAgoFromTime(props.date));
  }, [props.date]);
  return (
    <>
      <div className="container-fluid px-0">
        <Row>
          <Col xs={2} md={1} className="pl-0 text-left text-lg-center">
            <img src={avtar} alt="avtar"></img>
          </Col>
          <Col xs={10} md={11} className="mb-4 pr-0">
            <div className="row">
              <span className="commentHeader text-left">
                {props.username}

                <span className="commentsTime"> {timeAgo}</span>
              </span>
            </div>
            <div className="row">
              <span className="commentsText text-left">{props.comment}</span>{' '}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Comments;
