import { VidApi } from './api';
import { TestDto } from '../models/TestDto';
import { AnswersQuestionSetDto } from '../models/AnswersQuestionSetDto';
import { ResultDto } from '../models/ResultDto';
import { QuestionAnswerSet } from '../models/QuestionAnswerSet';
import { ResultGradeDto } from '../models/ResultGradeDto';

export class TestService extends VidApi {
  public async takeTest(data: TestDto): Promise<QuestionAnswerSet[]> {
    console.log('testData' + JSON.stringify(data));
    const res = await this.PostObjectAuth(
      'test/auth/createTest',
      'create new test',
      data
    );
    console.log('testRest' + JSON.stringify(res));
    return res;
  }
  public async submitTest(data: AnswersQuestionSetDto): Promise<ResultGradeDto> {
    console.log('testData' + JSON.stringify(data));
    const res = await this.PostObjectAuth(
      'test/auth/submitTest',
      'submit test',
      data
    );
    console.log('testRest' + JSON.stringify(res));
    return res;
  }

  public async getResult(data: TestDto): Promise<ResultDto> {
    console.log('testData' + JSON.stringify(data));
    const res = await this.PostObjectAuth(
      'test/auth/result',
      'Get Result',
      data
    );
    console.log('testRest' + JSON.stringify(res));
    return res;
  }
}
