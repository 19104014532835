import React from 'react';
import { IsAuthenticated } from '../helpers/withAuthentication';
import thankyou from '../assets/images/img_thank_you.png';
import { Button } from 'react-bootstrap';
import './thankyou.css';
interface IThankYou{
  showResult:any;
}
const ThankYou: React.FC<IThankYou> = (props) => {
 
  return (
    <div className="container-fluid">
      <div>
        <img src={thankyou} alt="" className="thankyouImage"></img>
      </div>
      <div className="row dvjustify successText1">
        <p className="mb-0">Your test is submitted <span className="successColor">successfully</span> </p>
      </div>
      <div className="row dvjustify dvalign successText2">
        <p className="mb-0">Please click below for.. </p>
      </div>
      <div className="row dvjustify">
        <Button className="btnresult" type="button" onClick={props.showResult}>
          Result
        </Button>
      </div>
    </div>
  );
};
export default IsAuthenticated(ThankYou, true);
