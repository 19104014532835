import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import btnclose from '../assets/images/btnclose.png';
import { CourseService } from '../services/courseService';
import { IsAuthenticated } from '../helpers/withAuthentication';
import { useForm } from 'react-hook-form';
//import { CategoryDto } from '../../models/CategoryDto';
import './categoryModal.css';
import { setToastShow } from '../redux/actions/app';
import { useDispatch } from 'react-redux';
import { CategoryDto } from '../models/CategoryDto';
interface ICategoryModal {
  show: boolean;
}
export const CategoryModal: React.FC<ICategoryModal> = (props) => {
  const { register, handleSubmit } = useForm<CategoryDto>();
  const [show, setShow] = useState(props.show);
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };
  //const handleShow = () => setShow(true);

  const onSubmit = handleSubmit(async (data) => {
    const delayInMilliseconds = 2000;
    console.log(data);
    const courseservice: CourseService = new CourseService();
    const token = await courseservice.addnewcategory(data);
    dispatch(setToastShow(true));
    setTimeout(function () {
      handleClose();
    }, delayInMilliseconds);
    console.log(token);
  });
  useEffect(() => {
    console.log(props.show + 'This was called');
    setShow(props.show);
  }, [props.show]);

  return (
    <form>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="bg-transparent col-md-12 col-sm-12 modelDim"
        centered
      >
        <Modal.Body className="bg-transparent">
          <div className="container-fluid p-0 m-0 bg-transparent">
            <img
              src={btnclose}
              alt=""
              className="closeButton"
              onClick={handleClose}
            ></img>
            <div className="container">
              <div className="row w modalform">
                <form onSubmit={onSubmit}>
                  <div className="form-group col-md-8  form">
                    <div className="lbl">
                      <label>
                        Type New Category <span className="color">* </span>
                      </label>
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control input-text"
                        placeholder="Exiting / Add New Category"
                        name="name"
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                  <div className="row ">
                    <Button
                      id="submit"
                      className="btn btnsubmit btnadd col-md-4"
                      type="Add"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default IsAuthenticated(CategoryModal, false);
