import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './signUp.css';
import btnclose from '../assets/images/btnclose.png';
import btnclose2 from '../assets/images/ic_close.svg';
import signup from '../assets/images/signup.png';
import { useHistory } from 'react-router-dom';
import {
  setAuthentication,
  setCurrentUser,
  setIsLoading,
  setShowSingUp,
} from '../redux/actions/app';
import { useDispatch, useSelector } from 'react-redux';
import SignUpForm from './signupForm';
import LoginForm from './loginForm';
import GoogleLogin from 'react-google-login';
import { UserDto } from '../models/UserDto';
import { AuthService } from '../services/authService';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
export const SignUp: React.FC = () => {
  const showModal: boolean = useSelector(
    (state) => state['app'].setShowSignUpModal
  );
  const [userData, setUserData] = useState(new UserDto());
  const [isSocial, setSocial] = useState(false);
  const [showSignUpLocal, setShowSignUpLocal] = useState(false);
  // const[userData,SetUserData] = useState(new UserDto());
  //const [showLogin, setShowLogin] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setShowSingUp(false));
    history.push('/courses');
  };
  const handleshowSignUpLocal = () => {
    setShowSignUpLocal(true);
    setShowForm(false);
  };
  // const handleShowlogin = () => {
  //   setShowLogin(true);
  //   // setShowForm(false);
  // };

  const [showFacebook, setShowFacebook] = useState(false);
  const handleGoogleLoginSuccess = async (response) => {
    dispatch(setIsLoading(true));
    const authService: AuthService = new AuthService();
    const userDto: UserDto = new UserDto();

    userDto.email = response.profileObj.email;
    userDto.fullName = response.profileObj.name;
    userDto.providerId = response.profileObj.googleId;
    userDto.provider = 'GOOGLE';
    userDto.imageUrl = response.profileObj.imageUrl;
    userDto.password = response.profileObj.email;
    authService.connectSocialCheck(userDto).then((res) => {
      if (res.signUpComplete == false) {
        setSocial(true);
        setUserData(res);
        dispatch(setIsLoading(false));

        handleshowSignUpLocal();
      } else {
        authService.connectSocialComplete(res).then((result) => {
          dispatch(setAuthentication(true));
          dispatch(setShowSingUp(false));
          dispatch(setCurrentUser(result));
          history.push('/courses');
        });
      }
    });
  };

  // const handleFacebookLogin = async () => {
  //   dispatch(setIsLoading(true));
  //   const authService: AuthService = new AuthService();
  //   const userDto: UserDto = new UserDto();

  //   userDto.fullName = 'Sagar Jadhav';
  //   userDto.providerId = '10225768168575909';
  //   userDto.provider = AuthProvider.FACEBOOK;

  //   const user: UserDto = await authService.addnewuserSocial(userDto);
  //   console.log(JSON.stringify(user));
  //   //dispatch(setAuthentication(true));
  //   //dispatch(setShowSingUp(false));
  //   //dispatch(setCurrentUser(user));
  //   dispatch(setIsLoading(false));
  //   //history.push('/courses');
  // };
  const responseFacebook = (response) => {
    console.log(JSON.stringify(response));
    dispatch(setIsLoading(true));
    const authService: AuthService = new AuthService();
    const userDto: UserDto = new UserDto();
    
    userDto.fullName = response.name;
    userDto.providerId = response.id;
    userDto.provider = 'FACEBOOK';
    
    authService.connectSocialCheck(userDto).then((res) => {
      if (res.signUpComplete == false) {
        setSocial(true);
        setUserData(res);
        dispatch(setIsLoading(false));

        handleshowSignUpLocal();
      } else {
        authService.connectSocialComplete(res).then((result) => {
          dispatch(setAuthentication(true));
          dispatch(setShowSingUp(false));
          dispatch(setCurrentUser(result));
          history.push('/courses');
        });
      }
    });
  };
  const showFacebookLogin = () => {
    setShowFacebook(!showFacebook); // Changed for FB
    // setShowFacebook(false);
  };
  const handleGoogleLoginFailure = (error) => {
    console.log('Login Failure ', error);
  };
  return (
    <form>
      <Modal
        show={showModal}
        onHide={handleClose}
        animation={false}
        className="bg-transparent col-md-12 col-sm-12 px-0 modelDim"
        centered
      >
        <Modal.Body className="bg-transparent signUpModal">
          <div className="container-fluid p-0 m-0 bg-transparent">
            <img
              src={btnclose}
              alt=""
              className="closeButton d-none d-lg-block"
              onClick={handleClose}
            ></img>
            <img
              src={btnclose2}
              alt=""
              className="closeButton2 d-block d-lg-none"
              onClick={handleClose}
            ></img>
            <div className="container-fluid">
              <div className="row">
                <div className="col-5 partnerSide px-0 d-none d-lg-block">
                  <h3 className="header">PARTNERS</h3>
                  <p className="descr pl-5">
                    Under our “Partner to Progress” approach, we link up with
                    other organizations to reach our goals: Social Improvement
                    and Sustainable Transformation.
                  </p>

                  {/* <img src={deloitte} alt="" className="partnerimg"></img> */}
                  <img src={signup} alt="" className="partnerimg"></img>
                </div>
                <div className="col-12 col-lg-7 signup align-items-center px-0 pr-lg-0 ">
                  {isSocial == true && (
                    <div className="signupDetails text-center mt-4 mt-lg-5 mx-2 mx-md-5 mx-lg-0 mb-lg-4">
                      Complete your Profile 
                    </div>
                  )}
                  {showForm && (
                    <div className="div-wrap">
                      <div className="row">
                        <span className="headertext col text-center mb-3 mb-lg-0">
                          Welcome to Assist Academy
                        </span>
                      </div>
                      <div className="row">
                        <span className="subheader col text-center">
                          Sign in by entering the information below
                        </span>
                      </div>
                      <div>
                        <div className="row dvsocial">
                          <div className="col-12 col-lg-6 mb-4 mb-lg-0 px-2 text-center">
                            {showFacebook && (
                              <FacebookLogin
                                appId="4055724657781734"
                                autoLoad
                                callback={responseFacebook}
                                render={() => (
                                  <button
                                    className="socialbuttons btnfacebook"
                                    type="button"
                                  >
                                    <i className="fab fa-facebook-square mr-2 facebookIcon"></i>{' '}
                                    Login With Facebook
                                  </button>
                                )}
                              />
                            )}
                            {!showFacebook && (
                              <button
                                className="socialbuttons btnfacebook"
                                type="button"
                                onClick={showFacebookLogin}
                              >
                                <i className="fab fa-facebook-square mr-2 facebookIcon"></i> Login
                                With Facebook
                              </button>
                            )}
                          </div>

                          <div className="col-12 col-lg-6 col px-2 text-center">
                            <GoogleLogin
                              clientId={
                                '564493819601-1mnvlbvjfl7eq2tkup0ng5ae82b6qf71.apps.googleusercontent.com'
                              }
                              buttonText="Login With Google"
                              onSuccess={handleGoogleLoginSuccess}
                              onFailure={handleGoogleLoginFailure}
                              cookiePolicy={'single_host_origin'}
                              className="socialbuttons"
                            />
                          </div>
                        </div>
                        <div className="mob">
                          <div className="row dvsocialmob">
                            <div className="  col text-center">
                              {showFacebook && (
                                <FacebookLogin
                                  appId="4055724657781734"
                                  autoLoad
                                  callback={responseFacebook}
                                  render={() => (
                                    <button className="btn btn-primary m-3">
                                      <i className="fa fa-facebook-square facebookIcon"></i> Login
                                      With Facebook
                                    </button>
                                  )}
                                />
                              )}
                              {!showFacebook && (
                                <button
                                  className="socialbuttons btnfacebook"
                                  type="button"
                                  onClick={showFacebookLogin}
                                >
                                  <i className="fa fa-facebook-square fbsquare facebookIcon"></i>{' '}
                                  Login With Facebook
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="row dvsocialmob">
                            <div className="col text-center">
                              <GoogleLogin
                                clientId={
                                  '564493819601-1mnvlbvjfl7eq2tkup0ng5ae82b6qf71.apps.googleusercontent.com'
                                }
                                buttonText="Login With Google"
                                onSuccess={handleGoogleLoginSuccess}
                                onFailure={handleGoogleLoginFailure}
                                cookiePolicy={'single_host_origin'}
                                className="socialbuttons"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="text-center">
                        <h5 className="subheader txt px-0">
                          &nbsp;OR SIGN IN WITH EMAIL&nbsp;
                        </h5>
                      </div>

                      <div>
                        {' '}
                        <LoginForm></LoginForm>
                        <div className="  row">
                          <span className="text-center footertext col">
                            Not a member yet ? &nbsp;
                            <a
                              id="btnsignup"
                              onClick={handleshowSignUpLocal}
                              href="#"
                            >
                              Register Here
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {showSignUpLocal && (
                    <SignUpForm
                      userData={userData}
                      isScocial={isSocial}
                    ></SignUpForm>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};
