import { SponsorDto } from '../models/SponsorDto';
import { VidApi } from './api';

export class SponsorService extends VidApi {

    public async getSponsor(): Promise<SponsorDto[]> {
        const res = await this.Get(`sponsor`, 'Get All Sponsor');
       
        console.log(JSON.stringify(res));
        return res;
      }

      public async addnewsponsor(sponsordata: SponsorDto): Promise<any> {
        console.log('addnewpartner' + JSON.stringify(sponsordata));
        const result = await this.PostObjectAuth(
          'sponsor/auth/addnewsponsor',
          'addNewSponsor',
          sponsordata
        );
        console.log('------' + JSON.stringify(result));
        return result;
      }
}