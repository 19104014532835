import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setToastShow } from '../redux/actions/app';
import { AppStateModel } from '../redux/reducers/app';
import './toast.css';

interface IToastForAll {
  header: string;
  msg: string;
}
const ToastForAll: React.FC<IToastForAll> = (props) => {
  const showToast: boolean = useSelector((state) => state['app'].toastShow);
  const letmeSee: AppStateModel = useSelector(
    (state) => state['app'].toastShow
  );
  useEffect(() => {
    console.log('showToast' + showToast);
    console.log(JSON.stringify(letmeSee));
  }, []);

  const dispatch = useDispatch();

  return (
    <div>
      <Toast
        className="toastLocation"
        onClose={() => dispatch(setToastShow(false))}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto toastHeader">Assist Academy{props.header}</strong>
          <small className="ml-3">update</small>
        </Toast.Header>
        <Toast.Body
        className="toastMessage">{props.msg}</Toast.Body>
      </Toast> 
    </div>
  );
};

export default ToastForAll;
