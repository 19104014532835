import React, { useEffect, useState } from 'react';
import { IsAuthenticated } from '../helpers/withAuthentication';
import { CourseDto } from '../models/CourseDto';
import { CourseService } from '../services/courseService';
import ReactPlayer from 'react-player';
import VideoCoursesTile from '../components/videocoursetile';
import { Button } from 'react-bootstrap';
import './videoComponent.css';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserDto } from '../models/UserDto';
import { VideoLogDto } from '../models/VideoLogDto';
import './comments.css';
import previmg from '../assets/images/prev.png';
import nextimg from '../assets/images/next.png';
import prevgreyimg from '../assets/images/prev_gray.png';
import nextgreyimg from '../assets/images/next_gray.png';
import useCollapse from 'react-collapsed';
import { useForm } from 'react-hook-form';
import { CommentDto } from '../models/CommentDto';
import { CommentService } from '../services/commentService';
import ToastForAll from '../components/Toast';
import { setToastShow } from '../redux/actions/app';
interface IVideoComponent {
  id: string;
}
import Comments from '../components/comments';

const VideoComponent: React.FC<IVideoComponent> = (props) => {
  const [course, setCourse] = useState(new CourseDto());

  const [count, setCount] = useState(0);

  const [prev, setPrev] = useState('-1');
  const [next, setNext] = useState('-1');
  const user: UserDto = useSelector((state) => state['app'].currentUser);
  const getCourses = async () => {
    const courseApi: CourseService = new CourseService();
    courseApi.getCoursesVideo(props.id).then(res=>{
      //console.log('Let me see it '+JSON.stringify(res));

      setCourse(res);
    });
  };
  const history = useHistory();
  const [courseList, setCourseList] = useState(new Array<CourseDto>());
  const { register, handleSubmit } = useForm<CommentDto>();
  const [toastMsg, setToastMsg] = useState(' ');
  const dispatch = useDispatch();
  const [comments, setComments] = useState(new Array<CommentDto>());
  const getCoursesvideo = async () => {
    const courseApi: CourseService = new CourseService();
    await courseApi.getCourses(props.id).then((res) => {
      setCourseList(res);
      checkCount(res);
      //console.log(res[0]);
      //console.log(res[res.length - 1]);
      if (res != null && res.length > 0) {
        if (res[0].category.id == course.headerCategory.id) setNext(res[0].id);
        if (res[res.length - 1].category.id == course.headerCategory.id)
          setPrev(res[res.length - 1].id);
      }
    });
  };
  const playVideo = (id) => {
    //console.log('PlayClicked');
    history.push('/video/' + id);
  };
  const handleSearch = (tag) => (event) => {
    history.push('/courses/search/' + tag);
   console.log(event);
  };

  const handleStart = async () => {
    const courseApi: CourseService = new CourseService();
    const vidlog = new VideoLogDto();
    vidlog.courseId = props.id;
    vidlog.email = user.email;
    await courseApi.addVideoLogs(vidlog);
    //console.log('Media Started');
  };
  const checkCount = (res: CourseDto[]) => {
    let cnt = 1;
    res.forEach((element) => {
      if (element.category.id === course.headerCategory.id) {
        cnt = cnt + 1;
      }
      setCount(cnt);
    });
  };
  const test = () => {
    history.push('/test/' + props.id);
  };
  const [renderChildren, setRenderChildren] = React.useState(false);
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    defaultExpanded: false,
    onExpandStart() {
      setRenderChildren(true);
    },
    onCollapseEnd() {
      setRenderChildren(false);
    },
  });
  const onSubmit = handleSubmit(async (data, e) => {
    const commentApi: CommentService = new CommentService();

    e.preventDefault();
    setToastMsg('Comment Added');
    data.course_id = props.id;
    const user: UserDto = JSON.parse(localStorage.getItem('user'));
    data.user_email = user.email;
   // //console.log('data::', JSON.stringify(data));
    commentApi.addComment(data).then(() => {
      dispatch(setToastShow(true));

      getComments();
    });
  });
  const getComments = async () => {
    const commentApi: CommentService = new CommentService();

    setComments(await commentApi.getCommentsById(props.id));
  };
  useEffect(() => {
    getCourses();
  }, []);
  useEffect(() => {
    getCoursesvideo();
     console.log('course.video' + course.video);
  }, [course]);
  useEffect(() => {
    getComments();
  }, []);
  return (
    <div className="container-fluid px-0">
      <ToastForAll header="" msg={toastMsg}></ToastForAll>
      <div className="videobg">
        <div className="container px-lg-0">
          <div className="row">
            <div className="col">
              <div className="row">
                <p className="col-md-12 videoheader text-left">{course.name}</p>
              </div>
              <div className="row">
                <div className="col-12 col-lg-8">
                  <div className="row mb-2">
                    <div className="col-12 col-md-6 text-left  align-self-center">
                      <span className="subVideoHeader">
                        {course.headerCategory.name}
                        <span className="mx-1"> ({count} Videos)</span>
                      </span>
                    </div>
                    <div className="d-none d-md-block col-md-6 text-right">
                      <Button
                        type="button"
                        className="btntest videobtns"
                        onClick={test}
                      >
                        Take a Test
                      </Button>
                    </div>
                  </div>
                  <ReactPlayer
                    className="courseVideo"
                    url={course.video}
                    width="100%"
                    height="50vh"
                    controls={true}
                    onStart={handleStart}
                  />

                  <div className="videobtns">
                    <div className="row  py-2 px-0">
                      {/* <div className="col-0 text-left d-none">
                        <Button className="downloadBtn btn btn-light p-0">
                          <span className="btnDownload"></span>
                        </Button>
                      </div> */}
                      <div className="col text-left d-block d-md-none">{/* videobtnsmobile text-right */}
                        <Button
                          type="button"
                          className="btntest videobtnsmob ml-0"
                          onClick={test}
                        >
                          Take a Test
                        </Button>
                      </div>

                      <div className="col text-right videobtns btnalign mt-0">
                        <div className="videobtns my-0 my-lg-1">
                          <img
                            src={previmg}
                            alt=""
                            className="nextprevbtn prevbtn"
                            onClick={() => playVideo(prev)}
                            hidden={prev != '-1' ? false : true}
                          />
                          <img
                            src={prevgreyimg}
                            alt=""
                            className="nextprevbtn prevbtn greyButton"
                            
                            hidden={prev != '-1' ? true : false}
                            
                          />
                          <img
                            src={nextimg}
                            alt=""
                            className="nextprevbtn"
                            onClick={() => playVideo(next)}
                            hidden={next != '-1' ? false : true}
                          />
                          <img
                            src={nextgreyimg}
                            alt=""
                            className="nextprevbtn greyButton greyButton"
                          
                            hidden={next != '-1' ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="videobtnsmobile">
                    <div className="row  py-3 px-0">
                      <div className="col text-right videobtnsmobile">
                        <Button
                          type="button"
                          className="btntest btntestmob "
                          onClick={test}
                        >
                          Take a Test
                        </Button>
                      </div>
                      <div className=" text-right col  btnalign">
                        <div className="">
                          <img
                            src={previmg}
                            alt=""
                            className="nextprevbtn prevbtn"
                            onClick={() => playVideo(prev)}
                            hidden={prev != '-1' ? false : true}
                          />
                          <img
                            src={prevgreyimg}
                            alt=""
                            className="nextprevbtn prevbtn greyButton"
                            
                            hidden={prev != '-1' ? true : false}
                            
                          />
                          <img
                            src={nextimg}
                            alt=""
                            className="nextprevbtn"
                            onClick={() => playVideo(next)}
                            hidden={next != '-1' ? false : true}
                          />
                          <img
                            src={nextgreyimg}
                            alt=""
                            className="nextprevbtn greyButton greyButton"
                          
                            hidden={next != '-1' ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="d-none d-lg-block col-md-4 dvtags ">
                  <h2 className="videoheader"></h2>
                  <div className="rightpanel">
                    {courseList.map((course, index) => (
                      <div className="videopanel mt-2" key={index}>
                        <VideoCoursesTile course={course} isAuthenticated={true} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2 px-3 px-lg-0">
          <div className="col-md-8  pr-lg-4">
            <div className="row text-left">
              <span className="vidDescTitle mb-1">Description</span>
              <p></p>
            </div>

            <div className="container-fluid">
              <div className="row mobiletags m-0"></div>
            </div>

            <div className="row">
              <p className="videodecr text-justify">{course.description}</p>
            </div>
            <div className="row mobiletags">
              <div className="row ">
                <p className="vidDescTitle mb-2 text-left mx-2 px-2">Tags</p>
                <div className="col-md-12 text-left mx-2 mb-4 px-2">
                  {course.tags != null &&
                    course.tags.map((tag, index) => (
                      <span key={index}>
                        <button
                          className="btn btn-link tagsName"
                          key={tag}
                          onClick={handleSearch(tag)}
                        >
                          {tag}
                        </button>
                      </span>
                    ))}
                </div>
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="row divcmnt mb-4">
                <p className="vidDescTitle mb-2">Rating and Comment</p>
                <textarea
                  className="formatCmt"
                  name="comment"
                  placeholder="Enter your comments here and share your rating"
                  ref={register({ required: true })}
                ></textarea>
              </div>
              <div className="row">
                <div className="starrating risingstar d-flex align-items-start justify-content-end flex-row-reverse col text-left pl-0">
                  <input
                    type="radio"
                    id="star5"
                    name="rating"
                    value="5"
                    ref={register({ required: true })}
                  />
                  <label htmlFor="star5" title="Very Good">
                    
                  </label>
                  <input
                    type="radio"
                    id="star4"
                    name="rating"
                    value="4"
                    ref={register({ required: true })}
                  />
                  <label htmlFor="star4" title="Good">
                    
                  </label>
                  <input
                    type="radio"
                    id="star3"
                    name="rating"
                    value="3"
                    ref={register({ required: true })}
                  />
                  <label htmlFor="star3" title="Average">
                    
                  </label>
                  <input
                    type="radio"
                    id="star2"
                    name="rating"
                    value="2"
                    ref={register({ required: true })}
                  />
                  <label htmlFor="star2" title="Can be better">
                  
                  </label>
                  <input
                    type="radio"
                    id="star1"
                    name="rating"
                    value="1"
                    ref={register({ required: true })}
                  />
                  <label htmlFor="star1" title="Not good">
                  
                  </label>
                </div>

                <div className="col text-right pr-0">
                  {' '}
                  <Button type="Submit" className="btncmnt">
                    Submit
                  </Button>
                </div>
              </div>
            </form>
            <div className="row mb-4">
              <div className="col text-left px-0">
                <span className="commentHeader mx-0">
                  {comments.length} &nbsp; Comments
                </span>
              </div>
            </div>
            <div className="mt-2">
              {comments.map((comment, index) => (
                <div key={index}>
                  <Comments
                    username={comment.username}
                    comment={comment.comment}
                    date={comment.date}
                  ></Comments>
                </div>
              ))}
            </div>
            <div className="row mobiletags">
              <div className="container">
                <div className="row w ">
                  <h4 className="videohead">More Videos</h4>

                  <div
                    {...getCollapseProps({
                      style: { backgroundColor: 'lightblue' },
                    })}
                  >
                    {renderChildren && (
                      <div className="rightpanel">
                        {courseList.map((course, index) => (
                          <div className="videopanel m-2" key={index}>
                            <VideoCoursesTile
                              course={course}
                              isAuthenticated={true}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mobiletags dvtoggle">
              <button
                {...getToggleProps({ style: { display: 'block' } })}
                className="btntoggle"
              >
                {isExpanded ? (
                  <i
                    className="fa fa-angle-down togglecolor"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-angle-up togglecolor"
                    aria-hidden="true"
                  ></i>
                )}
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row px-0 px-md-3 dvtags">
              <p className="vidDescTitle mb-2">Tags</p>
              <div className="col-md-12 text-left m-0 p-0">
                {course.tags != null &&
                  course.tags.map((tag, index) => (
                    <span key={index}>
                      <button
                        className="btn btn-link tagsName"
                        key={tag}
                        onClick={handleSearch(tag)}
                      >
                        {tag}
                      </button>
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-block d-lg-none">
        <div className="row">
          <div className="col-12 dvtags ">
            <h2 className="videoheader"></h2>
            <div className="rightpanel">
              {courseList.map((course, index) => (
                <div className="videopanel mt-2" key={index}>
                  <VideoCoursesTile course={course} isAuthenticated={true} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsAuthenticated(VideoComponent, true);
