export class Util {
  public static getVideoId(url: string) {
    const re = /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
    const id = url.match(re)[7];
    return id;
  }

  public static getImage(vid: string) {
    const imageUrl = 'https://img.youtube.com/vi/' + vid + '/hqdefault.jpg';
    return imageUrl;
  }

  public static getMonthName(month: any) {
    let monthName = 'Jan';
    switch (month) {
      case 1:
        monthName = 'Jan';
        break;
      case 2:
        monthName = 'Feb';
        break;
      case 3:
        monthName = 'Mar';
        break;
      case 4:
        monthName = 'Apr';
        break;
      case 5:
        monthName = 'May';
        break;
      case 6:
        monthName = 'Jun';
        break;
      case 7:
        monthName = 'Jul';
        break;
      case 8:
        monthName = 'Aug';
        break;
      case 9:
        monthName = 'Sep';
        break;
      case 10:
        monthName = 'Oct';
        break;
      case 11:
        monthName = 'Nov';
        break;
      case 12:
        monthName = 'Dec';
        break;

      default:
        monthName = 'Jan';
        break;
    }

    return monthName;
  }

  public static getMonths() {
    const months = [
      { id: 1 ,abbreviation: 'Jan', name: 'January'  },
      { id: 2, abbreviation: 'Feb', name: 'February' },
      { id: 3, abbreviation: 'Mar', name: 'March' },
      { id: 4, abbreviation: 'Apr', name: 'April' },
      { id: 5, abbreviation: 'May', name: 'May' },
      { id: 6, abbreviation: 'Jun', name: 'June' },
      { id: 7, abbreviation: 'Jul', name: 'July' },
      { id: 8, abbreviation: 'Aug', name: 'August' },
      { id: 9, abbreviation: 'Sep', name: 'September' },
      { id: 10, abbreviation: 'Oct', name: 'October' },
      { id: 11, abbreviation: 'Nov', name: 'November' },
      { id: 12, abbreviation: 'Dec', name: 'December' },
    ];

    return months;
  }

  public static getTimeAgoFromTime(t:any){
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;
    const current = Date.now();

    const previous = Date.parse(t);

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return  Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return  Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return Math.round(elapsed/msPerYear ) + ' years ago';   
    }
  }
}
