import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/root';
import { composeWithDevTools } from 'redux-devtools-extension';

const enhancer = composeWithDevTools(applyMiddleware(thunk));

const configureStore = (preloadedState: any) => {
  const b = createStore(rootReducer, preloadedState, enhancer);
  return b;
};

export { configureStore };
