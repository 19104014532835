import { IsAuthenticated } from '../helpers/withAuthentication';
import './course.css';

const CoursesAuth: React.FC = () => {

  return (
    <div className="container-fluid m-0 p-0">
      
        
    </div>
  );
};

export default IsAuthenticated(CoursesAuth, false);
