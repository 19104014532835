import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SignUp } from '../components/signUp';
import { setShowSingUp } from '../redux/actions/app';
//import { AppStateModel } from '../redux/reducers/app';

export const IsAuthenticated = (Component: React.FC, auth: boolean) => (
  props
) => {
  const isAuthenticated: boolean = useSelector(
    (state) => state['app'].isAuthenticated
  );
  // const appN: any = useSelector((state) => state['app']);
  const checkAuth = () => {
    console.log('Auth checked ' + isAuthenticated);
    if (isAuthenticated == true) {
      auth = true;

      dispatch(setShowSingUp(false));
    } else {
      dispatch(setShowSingUp(true));
    }
    // console.log('Authenticated' + isAuthenticated);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    //console.log(JSON.stringify(appN));
    checkAuth();
  }, []);

  return (
    <>
      {auth && (
        <>
          {/* {console.log('asdasdasdasdas--' + isAuthenticated)} */}
          {Component != null && <Component {...props} />}
        </>
      )}

      {!auth && (
        <div>
          {Component != null && <Component {...props}></Component>}
          <SignUp></SignUp>
        </div>
      )}
    </>
  );
};
