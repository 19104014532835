import React, { useEffect, useState } from 'react';
import { CourseDto } from '../models/CourseDto';
import './coursesTile.css';
import { useHistory } from 'react-router-dom';
import { IsAuthenticated } from '../helpers/withAuthentication';
import btnPlay from '../assets/images/btn_play.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateModel } from '../redux/reducers/app';
import { CourseService } from '../services/courseService';
import { Modal, Button } from 'react-bootstrap';
import { setIsLoading } from '../redux/actions/app';
import editButton from '../assets/images/edit.svg';

interface ICourseTile {
  course: CourseDto;
}

const CoursesTile: React.FC<ICourseTile> = (props) => {
  const dispatch = useDispatch();
  const [readmore, setReadMore] = useState(false);
  const [showReadmore, setShowReadMore] = useState(false);
  const [show, setShow] = useState(false);
  const [courseId, setCourseId] = useState();
  const [optionsPopUpStatus, setOptionsPopUpStatus] = useState(false);
  const history = useHistory();
  const app: AppStateModel = useSelector((state) => state['app']);
  const playVideo = () => {
    console.log('PlayClicked');
    history.push('/video/' + props.course.id);
  };
  const deleteCourse = async (id) => {
    setShow(true);
    setCourseId(id);
    console.log('delete Clicked');
  };
  const handleClose = () => {
    setCourseId(null);
    setShow(false);
  };
  const handleDelete = async () => {
    dispatch(setIsLoading(true));
    const courseApi: CourseService = new CourseService();
    await courseApi.deleteCourse(courseId);
    setCourseId(null);
    setShow(false);
    dispatch(setIsLoading(false));
    history.push('/coursesall/');
  };

  const editcourse = async (id) => {
    history.push('/admin/editcourse/' + id);
  };
  useEffect(() => {
    if (
      props.course.description != null &&
      props.course.description.length > 40
    ) {
      setShowReadMore(true);
    }
    // console.log(JSON.stringify(app));
  }, []);
  const setReadMoreValue = () => {
    setReadMore(!readmore);
  };

  const handleOptionsOpen = () => {
    setOptionsPopUpStatus(true);
  };
  const handleOptionsClose = () => {
    setOptionsPopUpStatus(false);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="card-item col-md-12 p-0">
          <div className="course-card">
            <div className="course-card-inside">
              <div className="containerVImg" onClick={playVideo}>
                <img
                  className="img-thumbnail image p-0"
                  src={props.course.image}
                  alt=""
                ></img>
                <div className="overlay">
                  <span className="icon" title="play" onClick={playVideo}>
                    <img
                      src={btnPlay}
                      alt="play"
                      className="img-fluid imgPlay"
                    />
                  </span>
                </div>
              </div>
              {/* <div className="text-left">
                <span className="mediaName">MEDIA</span>
              </div> */}
              <div className="text-left textFit">
                <h4 className="">
                  <a
                    href={'/video/' + props.course.id}
                    className="videohead shadow-none"
                  >
                    {props.course.name}
                  </a>{' '}
                </h4>
                <p></p>
                {!readmore && (
                  <span>
                    <p className="vidDesc">
                      {props.course.description
                        ? props.course.description.substring(0, 30)
                        : ''}
                      {showReadmore && (
                        <span
                          className="btnReadMore"
                          onClick={setReadMoreValue}
                        >
                          Read More
                        </span>
                      )}
                    </p>
                  </span>
                )}
                {readmore && (
                  <p className="vidDesc">
                    {props.course.description}
                    {showReadmore && (
                      <span className="btnReadMore" onClick={setReadMoreValue}>
                        Read Less
                      </span>
                    )}
                  </p>
                )}
                {app.currentUser.role == 'ADMIN' && (
                  <div>
                    <div className="moreOptions" onClick={handleOptionsOpen}>
                      <span className={`${optionsPopUpStatus === true ? 'textWhite' : ''} noUserSelect`} >...</span>
                    </div>
                    <div className={`optionsBg ${optionsPopUpStatus === false ? 'd-none' : ''}`} onClick={handleOptionsClose}></div>
                    <div className={`moreOptionsPopUp textWhite ${optionsPopUpStatus === false ? 'd-none' : ''} noUserSelect`} onClick={handleOptionsClose}><span>...</span></div>
                    <div className={`optionsPopUp ${optionsPopUpStatus === false ? 'd-none' : ''}`}>
                      <div className="option" onClick={() => deleteCourse(props.course.id)}>
                        <i className="far fa-trash-alt deleteButton" ></i>
                        <span>Delete Course</span>
                      </div>
                      <div className="option" onClick={() => editcourse(props.course.id)}>
                        <img src={editButton} className="optionsIconImage" alt="Edit Details icon" />
                        <span>Edit details</span>
                      </div>
                    </div>
                  </div> 
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="modalClassForDelete">
        <Modal.Header closeButton>
          <Modal.Title className="headerTextModal">Delete Course</Modal.Title>
        </Modal.Header>
        <Modal.Body className="subHeaderText">
          Are you sure you want to Delete course ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IsAuthenticated(CoursesTile, true);
