import React, { useEffect, useState } from 'react';
import { IsAuthenticated } from '../helpers/withAuthentication';
import congratulation from '../assets/images/img_congratulation.svg';
import graduation from '../assets/images/img_graduate.svg';
import './test.css';
import { useHistory } from 'react-router';
interface IResult {
  showCert: any;
  score: any;
  grade: any;
  testId: any;
}
const Result: React.FC<IResult> = (props) => {
  const history =useHistory();
  const [msg, setMsg] = useState('');
  useEffect(() => {
    if (props.grade == 'F') {
      setMsg('To pass this test, you must achieve a score of at least 70%');
    }
  }, [props.grade]);
  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col">
          <img src={congratulation}></img>
        </div>
      </div>
      <div className="row my-4">
        <div className="col">
          You have completed this test with a score of{' '}
          <strong>{props.score}</strong> %
          <br />
          {msg}
          <br />
          {props.grade == 'F' && (
            <>
              <a
                href={'/test/' + props.testId}
                className="clickhere"
              >
                {' '}
                Click here
              </a>
              to retake the test.{' '}
            </>
          )}
        </div>
      </div>{' '}
      {props.grade != 'F' && (
        <div className="row my-4">
          <div className="col">
            <img src={graduation}></img>
          </div>
        </div>
      )}
      <div className="row my-4">
        {props.grade != 'F' && (
          <div className="col">
            {' '}
            <button
              type="button"
              className="testprevbtns"
              onClick={() => history.push('/courses')}
            >
              Cancel
            </button>
            <button
              type="button"
              className="testViewCert"
              onClick={props.showCert}
            >
              View Certificate
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default IsAuthenticated(Result, true);
