import React, { useState } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import btnclose from '../assets/images/btnclose.png';
import { CategoryDto } from '../models/CategoryDto';
import { UserDto } from '../models/UserDto';
// import { CourseService } from '../services/courseService';
import './Dashboard.css';
import {
  // setAuthentication,
  // setCurrentUser,
  setNavOpen,
} from '../redux/actions/app';

function Sidebar() {
  // const isAuthenticated: boolean = useSelector(
  //   (state) => state['app'].isAuthenticated
  // );
  const dispatch = useDispatch();
  //const [show, setShow] = useState(true);
  const [query, setQuery] = useState(' ');
  const visibility: boolean = useSelector((state) => state['app'].navOpen);
  //const [cat, setCat] = useState(new Array<CategoryDto>());
  const user: UserDto = useSelector((state) => state['app'].currentUser);
  const cat: CategoryDto[] = useSelector((state) => state['app'].categories);

  const history = useHistory();
  const handleClose = () => {
    dispatch(setNavOpen(!visibility));
  };
  // const logout = () => {
  //   dispatch(setCurrentUser(new UserDto()));
  //   dispatch(setAuthentication(false));
  //   localStorage.clear();
  //   history.push('/');
  // };
  // const signIn = () => {
  //   history.push('/coursesAuth');
  // };
  const handleInput = (event) => {
    setQuery(event.target.value);
  };
  const handleCategorySearch = (cat) => (event) => {
    console.log(event);
    history.push('/courses/filter/' + cat);
  };
  const handleSearch = () => {
    if (query.length > 1) history.push('/courses/search/' + query);
  };
  return (
    <div className="container-fluid App">
      <div className="wrapper">
        <div className="navclose row">
          <img
            src={btnclose}
            alt=""
            className="closeButton p-1"
            onClick={handleClose}
          ></img>
        </div>
        {!visibility && (
          <nav id="sidebar">
            <div className="sidebarinner">
              {/* <ul>
                <li className="nav-item navheadertext sidemob dropleft mr-5">
                  {isAuthenticated && (
                    <span className="">
                      <section
                        className="dropdown-toggle "
                       
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        
                        <span className="userName " style={{ color: '#659a30' }}>
                          <i
                            className="fas fa-user-circle famob"
                            style={{ color: '#659a30' }}
                          ></i>
                        </span>
                        <span className="dropdown-item   userName userprofile text-center" style={{ color: '#659a30' }}>
                          {user['fullName']}
                        </span>
                      </section>
                      <div
                        className="dropdown-menu dropdrownLeft"
                        aria-labelledby="navbarDropdown"
                      >
                       
                        <button type="button" className="userprofile">
                          <i className="fas fa-file-alt"></i>&nbsp;User Profile
                        </button>
                        <div className="dropdown-divider"></div>
                        <span className="dropdown-item text-center">
                          <button className="btnLogout" onClick={logout}>
                            Logout
                          </button>
                        </span>
                      </div>
                    </span>
                  )}
                  {!isAuthenticated && (
                    <span className=" navheadertext navheaderguest mobileMenu">
                      <button className="btnApp" onClick={signIn} style={{ color: '#659a30' }}> 
                        Sign In
                      </button>
                    </span>
                  )}
                </li>
              </ul> */}
              <div className="container-fluid my-4">
                <InputGroup className="mb-3">
                  <FormControl
                    className="textsearch px-4"
                    onChange={handleInput}
                    placeholder="Search "
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Append>
                    <Button variant="link" onClick={handleSearch}>
                      {' '}
                      <i className="fa fa-search"></i>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              <ul className="components list-unstyled">
                <li className="active">
                  <p className="navul">
                    <a href="/courses/" className="navul">
                      All Courses
                    </a>
                  </p>
                </li>
              </ul>

              {cat != null &&
                cat.map((cat, key) => (
                  <div key={key}>
                    <ul className="list-unstyled components">
                      <li className="active">
                        <p className="navul">
                          <button
                            className="btn btn-link catname shadow-none"
                            onClick={handleCategorySearch(cat.id)}
                          >
                            {cat.name}
                          </button>
                        </p>
                      </li>
                    </ul>
                    {/* {cat.subcategories.map((subcat, key) => (
                  <ul className="list-unstyled components" key={key}>
                    <li className="active">                      <button
                        className="btn btn-link subcatname shadow-none"
                        onClick={handleCategorySearch(subcat.name)}
                      >
                        {subcat.name}
                        <i
                          className="fa fa-caret-right caret"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </li>
                  </ul>
                ))} */}
                  </div>
                ))}

              {user.role == 'ADMIN' && (
                <div>
                  <ul className="components list-unstyled">
                    <li className="active">
                      <button className="btn btn-link catname">ADMIN</button>
                    </li>
                  </ul>
                  <ul className="list-unstyled components">
                    <li className="active">
                      <p className="navul">
                        <a
                          href="/admin/addcourses"
                          className="btn btn-link subcatname shadow-none"
                        >
                          Add New Course{' '}
                          <i
                            className="fa fa-caret-right caret"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </p>
                    </li>
                  </ul>
                  <ul className="list-unstyled components">
                    <li className="active">
                      <p className="navul">
                        <a
                          href="/admin/videoreport"
                          className="btn btn-link subcatname shadow-none"
                        >
                          View Video Report{' '}
                          <i
                            className="fa fa-caret-right caret"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </p>
                    </li>
                  </ul>
                  {/* <ul className="list-unstyled components">
                    <li className="active">
                      <p className="navul">
                        <a
                          href="/admin/loginreport"
                          className="btn btn-link subcatname shadow-none"
                        >
                          View User Details{' '}
                          <i
                            className="fa fa-caret-right caret"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </p>
                    </li>
                  </ul> */}
                </div>
              )}
            </div>
          </nav>
        )}
        <div className="overlaysidebar" onClick={handleClose}></div>
      </div>
    </div>
  );
}

export default Sidebar;
