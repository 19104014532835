import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { configureStore } from './redux/store';

const store: Store = configureStore({});

ReactDOM.render(
      <Provider store={store}>
      <Router forceRefresh={true}>
        <App />
      </Router>
    </Provider>,
   document.getElementById('root')
);
