// import { UserDto } from '../models/UserDto';
//  import { useState } from 'react';

import { AuthDto } from '../models/AuthDto';
import { UserDto } from '../models/UserDto';
import { VidApi } from './api';

export class AuthService extends VidApi {
  public async getAuthentication(user: AuthDto): Promise<any> {
    // const data = {
    //   username: 'sagar@gmail.com',
    //   password: '12345678',
    // };
    try {
      const result = await this.PostObject(
        'authenticate',
        'AuthenticateUser',
        user
      );
     

      localStorage.setItem('authentication-token', result['jwt']);
      localStorage.setItem('user', JSON.stringify(result));
      localStorage.setItem('isAuth', JSON.stringify(true));
      console.log('------' + JSON.stringify(result));
      return result;
    } catch (ex) {
      return null;
    }
  }

  public async addnewuser(userdata: UserDto): Promise<any> {
    const result = await this.PostObject(
      'authenticate/signup',
      'addNewUser',
      userdata
    );
    localStorage.setItem('authentication-token', result['jwt']);
    localStorage.setItem('user', JSON.stringify(result));
    localStorage.setItem('isAuth', JSON.stringify(true));
    console.log('------' + JSON.stringify(result));
    return result;
  }

  public async connectSocialCheck(userdata: UserDto): Promise<UserDto> {
    const result = await this.PostObject(
      'authenticate/signupforsocialpartial',
      'addSocial',
      userdata
    );
    // localStorage.setItem('authentication-token', result['jwt']);
    // localStorage.setItem('user', JSON.stringify(result));
    // localStorage.setItem('isAuth', JSON.stringify(true));
    console.log('------' + JSON.stringify(result));
    return result;
  }

  public async connectSocialComplete(userdata: UserDto): Promise<UserDto> {
    const result = await this.PostObject(
      'authenticate/signupforsocialComplete',
      'updateUser',
      userdata
    );
   
     localStorage.setItem('authentication-token', result['jwt']);
     localStorage.setItem('user', JSON.stringify(result));
     localStorage.setItem('isAuth', JSON.stringify(true));
   
    return result;
  }
  public async userprofile(userdata: UserDto): Promise<UserDto> {
    // console.log('addcourse' + JSON.stringify(coursedata));
     const result = await this.PutbOjectAuth(
      'authenticate/signupforsocialComplete',
      'updateUser',
      userdata
     );
     // console.log('------' + JSON.stringify(result));
     return result;
   }
}
