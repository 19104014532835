export class AppSettings {
  public static async Get(key: string) {
    let config: any;
    try {
      config = await AppSettings.readConfig();
    } catch (e) {

      console.warn(`Failed to read configuration file:` + e);
    }
    try {
      return config[key];
    } catch (e) {
      console.warn(`Failed to read configuration: ` + key);
    }
    return config;
  }

  private static async readConfig(): Promise<any> {
    return new Promise((resolve, reject): void => {
      fetch(`/appSettings.json`)
        .then(
          (result) => {
            console.warn(`Failed to read configuration file:` + JSON.stringify(result));
            if (result.ok) resolve(result.json());
            else reject();
          },
          () => reject()
        )
        .catch((ex) => {
          console.log(ex);
        });
    });
  }
}
