import React, { useState } from 'react';
import { IsAuthenticated } from '../helpers/withAuthentication';
import imgquestions from '../assets/images/img_questions.png';
import './test.css';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { TestDto } from '../models/TestDto';
import { UserDto } from '../models/UserDto';
import { TestService } from '../services/testService';
import { QuestionAnswerSet } from '../models/QuestionAnswerSet';
// import { AnswersQuestionSetDto } from '../models/AnswersQuestionSetDto';
import { AnswerDto } from '../models/AnswerDto';
import { AnswersQuestionSetDto } from '../models/AnswersQuestionSetDto';
import { AnswersQuestionDto } from '../models/AnswersQuestionsDto';
import { setIsLoading } from '../redux/actions/app';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import Result from './result';
import Certificate from './certificate';

const Test: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [quesSet, SetQuestionSet] = useState(new Array<QuestionAnswerSet>());

  const [testStart, setTestStart] = useState(false);
  const [testReady, setTestReady] = useState(false);
  const [noTest, setNoTest] = useState(false);
  const [result, setResult] = useState(false);
  const [testId, setTestId] = useState('');
  const [cert, setCert] = useState(false);
  const [exam, setShowExam] = useState(true);
  const [testScore, setTestScore] = useState(0);
  const [grade, setGrade] = useState('');
  const [currentQuest, setCurrentQuest] = useState(1);
  // const [ansSet, setAnsSet] = useState(new AnswersQuestionSetDto());
  const [quesAns, setQuesAns] = useState(new Array<AnswersQuestionDto>());
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const getTestData = async () => {
    dispatch(setIsLoading(true));
    const testApi: TestService = new TestService();
    const testData = new TestDto();
    testData.course_id = id;
    const user: UserDto = JSON.parse(localStorage.getItem('user'));
    console.log(user);

    testData.user_email = user.email;

    testApi.takeTest(testData).then((res) => {
      console.log(JSON.stringify(res));
      if (res != null && res.length > 0) {
        SetQuestionSet(res);
        setTestReady(true);

        dispatch(setIsLoading(false));
      } else {
        setNoTest(true);
        dispatch(setIsLoading(false));
      }
    });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const showResult = () => {
    
    setResult(true);
  };
  const showCert = () => {
   
    setResult(false);
    setCert(true);
  };
  const submitTest = () => {
    dispatch(setIsLoading(true));
    setShow(false);
    const testApi: TestService = new TestService();
    const submitTestDTO = new AnswersQuestionSetDto();
    const user: UserDto = JSON.parse(localStorage.getItem('user'));

    submitTestDTO.courseId = id;
    submitTestDTO.email = user.email;
    submitTestDTO.set = quesAns;
    submitTestDTO.testId = quesSet[0].testId;
    setTestId(submitTestDTO.testId);
    testApi.submitTest(submitTestDTO).then((res) => {
      console.log('submit test--' + res);
      setTestScore(res.score);
      setGrade(res.grade);
      dispatch(setIsLoading(false));
      setShowExam(false);
      //setThankYou(true);
      showResult();
    });
    console.log(JSON.stringify(submitTestDTO));
  };
  const cancelTest = () => {
    window.location.reload();
    setTestStart(false);
  };
  const setAnswers = (ans: AnswerDto, ch: any) => {
    console.log(JSON.stringify(ans) + '---  check  ---' + ch);
    const one = new AnswersQuestionDto();
    one.testId = quesSet[currentQuest - 1].testDetailsId;
    one.questionId = quesSet[currentQuest - 1].questionDtoResponse.id;

    if (
      quesSet[currentQuest - 1].questionDtoResponse.multipleAnswers == false
    ) {
      let newListOfAnswers = new Array<AnswersQuestionDto>();
      newListOfAnswers = quesAns.filter((x) => x.questionId != ans.question_id);
      if (newListOfAnswers && newListOfAnswers != null) {
        one.answers.push(ans.id);
        newListOfAnswers.push(one);
        setQuesAns(newListOfAnswers);
      } else {
        newListOfAnswers = new Array<AnswersQuestionDto>();
        one.answers.push(ans.id);
        newListOfAnswers.push(one);
        setQuesAns(newListOfAnswers);
      }
    }
  };
  const setAnswersMultiple = (ans: AnswerDto, ch: any) => {
    console.log(JSON.stringify(ans) + '---  check  ---' + ch.target.checked);
    const one = new AnswersQuestionDto();
    one.testId = quesSet[currentQuest - 1].testDetailsId;
    one.questionId = quesSet[currentQuest - 1].questionDtoResponse.id;

    if (quesSet[currentQuest - 1].questionDtoResponse.multipleAnswers == true) {
      let newListOfAnswers = new Array<AnswersQuestionDto>();
      newListOfAnswers = quesAns.filter((x) => x.questionId == ans.question_id);
      let oldList = quesAns.filter((x) => x.questionId != ans.question_id);
      // console.log(JSON.stringify(newListOfAnswers));
      if (newListOfAnswers != null && newListOfAnswers.length > 0) {
        if (ch.target.checked == true) {
          if (
            newListOfAnswers[0].answers != null &&
            newListOfAnswers[0].answers.length < 1
          ) {
            newListOfAnswers[0].answers = new Array<string>();
          }
          if (!newListOfAnswers[0].answers.includes(ans.id))
            newListOfAnswers[0].answers.push(ans.id);
        } else {
          const index = newListOfAnswers[0].answers.indexOf(ans.id);
          if (index > -1) {
            newListOfAnswers[0].answers.splice(index, 1);
          }
        }
      } else {
        newListOfAnswers = new Array<AnswersQuestionDto>();
        one.answers.push(ans.id);
        newListOfAnswers.push(one);
      }

      if (oldList != null && oldList.length > 0) {
        oldList = oldList.concat(newListOfAnswers);

        setQuesAns(oldList);
      } else {
        setQuesAns(newListOfAnswers);
      }
    }
  };
  useEffect(() => {
    getTestData();
  }, []);

  return (
    <div className="container">
      {exam && (
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            className="modalClassForDelete"
          >
            <Modal.Header closeButton>
              <Modal.Title className="headerTextModal">
                Confirmation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="subHeaderText">
              Are you sure you want to submit the test? You cannot undo this
              action.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="success" onClick={submitTest}>
                Submit Test
              </Button>
            </Modal.Footer>
          </Modal>
          <img
            src={imgquestions}
            alt=""
            className="imgtest videobtns d-none d-md-block"
          ></img>
          {testReady && (
            <div hidden={!testStart}>
              <div className="col text-right px-0">
                <button
                  type="button"
                  className="testprevbtns cancelTestButton"
                  onClick={cancelTest}
                >
                  Cancel
                </button>
              </div>
              <div className="row mb-4">
                <div className="col text-left categoryHeader ml-0">
                  Total {quesSet.length} Questions ({currentQuest} of{' '}
                  {quesSet.length})
                </div>
              </div>
              <div className="row ansradio mb-3">
                <div className="col text-left ">
                  Q.{currentQuest}{' '}
                  {quesSet[currentQuest - 1].questionDtoResponse.question}{' '}
                </div>
              </div>

              <div className="row mb-3 mt-2">
                <div className="col">
                  <div className="row">
                    <div className="col text-left">
                      <div className="ansradio">
                        {quesSet.map((ques, index) => (
                          <div
                            key={index}
                            hidden={
                              quesSet[currentQuest - 1].questionDtoResponse
                                .multipleAnswers
                            }
                          >
                            {ques.questionDtoResponse.multipleAnswers ==
                              false &&
                              ques.answerDtoResponses.map((ans, key) => (
                                <div
                                  className="aform-check"
                                  key={key}
                                  hidden={index + 1 != currentQuest}
                                >
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={ans.question_id}
                                      id={ans.id}
                                      value={ans.id}
                                      onChange={(e) => setAnswers(ans, e)}
                                    />

                                    {ans.text}
                                  </label>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {quesSet.map((ques, index) => (
                    <div
                      key={index}
                      hidden={
                        !quesSet[currentQuest - 1].questionDtoResponse
                          .multipleAnswers
                      }
                    >
                      {ques.questionDtoResponse.multipleAnswers == true &&
                        ques.answerDtoResponses.map((ans, key) => (
                          <div
                            className="aform-check"
                            key={key}
                            hidden={index + 1 != currentQuest}
                          >
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={ans.id}
                                id={ans.id}
                                value={ans.id}
                                onChange={(e) => setAnswersMultiple(ans, e)}

                                // checked={isCheckedAns(ans)}
                              />

                              {ans.text}
                            </label>
                          </div>
                        ))}
                    </div>
                  ))}

                  {/* // quesSet[currentQuest - 1].answerDtoResponses.map(
                //   (ans, index) => (
                //     <div className="row" key={index}>
                //       <div className="col text-left">
                //         <div className="ansradio">
                //           <div className="form-check">
                //             <input 
                //               className="form-check-input"
                //               type="checkbox"
                //               name={'ans' + ans.id}
                //               id={'ans' + ans.id}
                //               value={ans.id}
                //               onChange={(e) => setAnswers(ans, e)}
                //             />
                //             <label
                //               className="form-check-label"
                //               htmlFor={'ans' + ans.id}
                //             >
                //               {ans.text}
                //             </label>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   )
                // )}*/}
                </div>
              </div>
            </div>
          )}
          <div className="mt-4" hidden={testStart}>
            <div className="row">
              <div className="col text-left mb-4">
                <span className="categoryHeader">Test Instructions</span>
              </div>
            </div>
            <ul className="mb-4">
              <ul list-style-type="disk">
                <li className="text-left">Please do not refresh page.</li>
              </ul>
              <ul>
                <li className="text-left">
                This course has multiple modules. Certificates will be generated once all modules' tests have been completed.
                </li>
              </ul>
            </ul>
            <div className="row">
              <div className="col text-right">
                {!noTest && (
                  <button
                    type="button"
                    onClick={() => setTestStart(true)}
                    className={
                      'testnextbtns ' + (!testReady ? 'disabledButton ' : '')
                    }
                    disabled={!testReady}
                  >
                    Start
                  </button>
                )}
                {noTest && (
                  <p>
                    <h6>Test is not available yet. Go back to{' '}<button
                     type="button"
                      className="testnextbtns"
                      onClick={() => history.push('/video/' + id)}
                    >
                      Course
                    </button> </h6>
                    
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-4" hidden={!testStart}>
            <div className="col px-0">
              <button
                type="button"
                className={
                  'testnextbtns ' + (currentQuest == 1 ? 'disabledButton ' : '')
                }
                disabled={currentQuest == 1}
                onClick={() => setCurrentQuest(currentQuest - 1)}
              >
                Prev
              </button>
            </div>
            <div className="col px-0">
              {' '}
              <button
                type="button"
                className={
                  'testnextbtns ' +
                  (currentQuest == quesSet.length ? 'disabledButton ' : '')
                }
                onClick={() => setCurrentQuest(currentQuest + 1)}
                disabled={currentQuest == quesSet.length}
              >
                Next
              </button>
            </div>
            {currentQuest == quesSet.length ? (
              <div className="col px-0">
                <button
                  type="submit"
                  className={
                    'testnextbtns ' +
                    (currentQuest != quesSet.length ? 'disabledButton ' : '')
                  }
                  onClick={handleShow}
                  disabled={currentQuest != quesSet.length}
                >
                  Submit
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
      {result && <Result score={testScore} showCert={showCert} grade={grade} testId={id}></Result>}
      {cert && (
        <Certificate testId={testId} testScore={testScore}></Certificate>
      )}
     
    </div>
  );
};
export default IsAuthenticated(Test, true);
