import { VidApi } from './api';
import { ReportAllViewDTO } from '../models/ReportAllViewDto';
import { Util } from '../helpers/util';
import { ReportDataByTimeDTO } from '../models/ReportDataByTimeDTO';


export class ReportService extends VidApi {

  public async getVideoReport(id:string): Promise<ReportAllViewDTO[]> {
    const res = await this.Get(`reports/videologs/${id}`, 'Get All VideoLogs');
    //console.log(JSON.stringify(res));

    return res;
  }
  public async getVideoReportData(unit:string): Promise<ReportDataByTimeDTO[]> {
    const res = await this.Get(`reports/video/${unit}`, 'Get All VideoLogs for chart');
    //console.log(JSON.stringify(res));
    if(unit=="month"){
    res.forEach(element => {
      element.name = Util.getMonthName(element.time);
    });}
    else if(unit=="week"){
      res.forEach(element => {
        element.name = 'Week '+element.time;
      });}
      else{
        res.forEach(element => {
          element.name =element.time;
        });}
    return res;
  }

  public async getVideoCount(): Promise<any> {
    const res = await this.Get('courses/auth/videocount', 'Get Video Count');
    console.log("getVideoCount ... "+JSON.stringify(res));

    return res;
  }
  public async getTestCount(): Promise<any> {
    const res = await this.Get('reports/testCount', 'Get Test Count');
    console.log("testCount ... "+JSON.stringify(res));

    return res;
  }
  public async getCertCount(): Promise<any> {
    const res = await this.Get('reports/certificateCount', 'Get Cert Count');
    console.log("certificateCount ... "+JSON.stringify(res));

    return res;
  }
  public async getVideoViews(): Promise<any> {
    const res = await this.Get('reports/videoViews', 'Get Cert Count');
    console.log("videoViews ... "+JSON.stringify(res));

    return res;
  }
}