import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import CoursesTile from '../components/coursesTile';
import { IsAuthenticated } from '../helpers/withAuthentication';
import { CourseDto } from '../models/CourseDto';
import { CourseService } from '../services/courseService';
import './course.css';

const SearchResult: React.FC = () => {
  const [courseList, setCourseList] = useState(new Array<CourseDto>());
  const { query } = useParams<{ query: string }>();
  // const visibility: boolean = useSelector((state) => state['app'].navOpen);
  // const history = useHistory();
  const getCourses = async () => {
    const courseApi: CourseService = new CourseService();
    setCourseList(await courseApi.getCoursesByCatSearch(query));

    //if (isAuthenticated) console.log(JSON.stringify(courseList));
  };
  useEffect(() => {
    getCourses();
    console.log(courseList);
  }, []);
  const display = courseList.map((course, key) => {
    return (
        <div className="item" key={key}>
          <CoursesTile course={course} isAuthenticated={true} />
        </div>
     
    );
  });
  return (
    <div className="container">
      <h4 className="categoryHeader mx-0">Search Results for {query} 
     </h4> 
      <div className="grid">{display}</div>
    </div>
  );
};

export default IsAuthenticated(SearchResult, true);
