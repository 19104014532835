import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CoursesTile from '../components/coursesTile';
// import CoursesTileMobile from '../components/courseTileMobile';
import { IsAuthenticated } from '../helpers/withAuthentication';
import { CourseDto } from '../models/CourseDto';
import { setIsLoading } from '../redux/actions/app';
// import { AppStateModel } from '../redux/reducers/app';
import { CourseService } from '../services/courseService';
import './course.css';

import CarouselCustom from '../components/carouselCustom';

const Courses: React.FC = () => {
  const [courseList, setCourseList] = useState(new Array<CourseDto>());
  const history = useHistory();
  const dispatch = useDispatch();

  const getCourses = async () => {
    dispatch(setIsLoading(true));
    const courseApi: CourseService = new CourseService();
    setCourseList(await courseApi.getCoursesByCat());
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    getCourses();
  }, []);

  const otherTopics = (result: CourseDto[]) => {
    let uniqueSubcat = result.map(function (obj) {
      return obj.headerCategory.name;
    });
    uniqueSubcat = uniqueSubcat.filter(function (v, i) {
      return uniqueSubcat.indexOf(v) == i;
    });

    const handlesubcat = (e) => {
      const query = e.target.innerText;
      history.push('/courses/search/' + query);
    };

    return (
      <>
        {uniqueSubcat.map((course, index) => (
          <div key={index}>
            <Button
              type="button"
              className="btnOtherTopics"
              id="myButton1"
              onClick={handlesubcat}
            >
              {course}
            </Button>
          </div>
        ))}
      </>
    );
  };

  const display = Object.keys(courseList).map((category, key) => {
    return (
    <div className="dummyclass">
      <div className="container px-0 pt-3 pb-5" key={key}>
        <h4 className="categoryHeader px-0 mb-3 mx-0">{category} </h4>
        <div className="row px-0 mx-0 mb-2">
          <div className="col text-left px-0">
            <Form inline className="frmwrap">
              <Button type="button" className="btnAll ml-0">
                All Topics
              </Button>
              {otherTopics(courseList[category])}
            </Form>
          </div>
        </div>
        <div className="grid px-0">
          {courseList[category].map((course, index) => (
            <div className="item" key={index}>
              <CoursesTile course={course} isAuthenticated={true} />
            </div>
          ))}
        </div>
        <div></div>
      </div>
    </div>
    );
  });

  const mobDisplay = Object.keys(courseList).map((category, key) => {
    return (
      <div className="container-fluid dummyclass px-0" key={key}>
        <h4 className="categoryHeader mx-0">{category}</h4>
        <div className="text-left mb-2">
          {' '}
          <span className="videoCount">
            {' '}
            ({courseList[category].length} Videos)
          </span>
        </div>
        <div className="row">
          <div className="col text-left topicsA px-2">
            <Form inline className="frmwrap">
              <Button type="button" className="btnAll">
                All Topics
              </Button>
              {otherTopics(courseList[category])}
            </Form>
          </div>
        </div>

        <CarouselCustom
          category={category}
          courseList={courseList}
        ></CarouselCustom>
      </div>
    );
  });

  return (
    <div className="container-fluid m-0">
      <div className="d-md-none d-block">{mobDisplay}</div>
      <div className="row d-none d-md-block">
        <div className="nonMobile">{display}</div>
      </div>
    </div>
  );
};

export default IsAuthenticated(Courses, true);
