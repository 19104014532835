import React, { useState } from 'react';
import './signUp.css';
import { UserDto } from '../models/UserDto';
import {
  setAuthentication,
  setShowSingUp,
  setCurrentUser,
  setIsLoading,
} from '../redux/actions/app';
import { AuthService } from '../services/authService';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
//import { useHistory } from 'react-router-dom';
import { AuthDto } from '../models/AuthDto';
import axios from 'axios';
const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  //const history = useHistory();
  const [error, setError] = useState(' ');
  const { register: login, handleSubmit: handleLogin } = useForm<AuthDto>();

  const onLogin = handleLogin(async (data) => {
    dispatch(setIsLoading(true));
    setError(' ');
    const authService: AuthService = new AuthService();

    const user: UserDto = await authService.getAuthentication(data);
    axios.interceptors.response.use(
      (response) => {
        if (response.status === 401) {
          alert('You are not authorized');
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
      }
    );
    if (user != null) {
      dispatch(setAuthentication(true));
      dispatch(setShowSingUp(false));
      dispatch(setCurrentUser(user));

      window.location.reload();
    } else {
      console.log(error);
      setError('Wrong Credentials');
      dispatch(setIsLoading(false));
    }

    //history.push('/coursesall');
  });
  // const handleClose = () => {
  //   dispatch(setShowSingUp(false));
  //   history.push('/courses');
  // };
  return (
    <div className="dvlogindtls">
      <form onSubmit={onLogin}>
        
        <div className="form-group row">
        <label className ="loginlabels">Email Address</label>
        <span className="color">*</span>

          <div className="input-group">
            
            <input
              className="form-control"
              type="email"
              name="username"
              ref={login({ required: true })}
             
            />
          </div>
        </div>
        <div className="form-group row">
        <label className ="loginlabels">Password</label>
        <span className="color">*</span>

          <div className="input-group">
          
            <input
              className="form-control"
              type="password"
              name="password"
              ref={login({ required: true })}
            //  placeholder="Password"
            />
          </div>
        </div>

        <div className="mt-5 mb-2 mb-md-4">
          {/* <Button
            id="cancel"
            className="btnreset col mx-1"
            onClick={handleClose}
          >
            Cancel
          </Button> */}
          <Button id="submit" className="btn btnsignin col" type="Submit">
            <i className="fa fa-lock" aria-hidden="true"></i>
           &nbsp; SIGN IN
          </Button>
        </div>
        <div className="row p-2 error">
          <div className="col text-center">{error}</div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
