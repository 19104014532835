import './App.css';
import { Route, Switch } from 'react-router-dom';
import Courses from './pages/course';
import Video from './pages/video';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCategories,
  setAuthentication,
  setCurrentUser,
  setNavOpen,
  setShowSingUp,
} from './redux/actions/app';
import Header from './components/header';
import Headbanner from './components/headbanner';
import Footer from './components/footer';
import Sidebar from './components/sidebar';
import btnfilter from './assets/images/btn_filter.png';
//import banner from './assets/images/banner.png';
import React, { Suspense, useEffect, useState } from 'react';
import uploadCourse from './pages/Admin/uploadCourse';
import Test from './pages/test';
import ThankYou from './pages/thankYou';
import Result from './pages/result';
import LoginReport from './pages/Admin/loginReport';
import VideoReport from './pages/Admin/videoReport';
import { CategoryModal } from './admin_modals/categoryModal';
import SearchResult from './pages/searchResults';
import { Partner } from './admin_modals//partner';
import { Sponsor } from './admin_modals/sponsor';
import { SubCategoryModal } from './admin_modals/subCategoryModal';
import Loader from './components/loader';
import CourseAuth from './pages/courseAuth';
import Certificate from './pages/certificate';
import UserProfile from './pages/userProfile';
import CategoryFilter from './pages/categoryFiltered';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const visibility: boolean = useSelector((state) => state['app'].navOpen);
  const app: any = useSelector((state) => state['app']);
  const rehydrate = () => {
    console.log('I was called');
    dispatch(getCategories());
    if (localStorage.getItem('user') != null) {
      dispatch(setAuthentication(true));
      dispatch(setShowSingUp(false));
      dispatch(setCurrentUser(JSON.parse(localStorage.getItem('user'))));
    }
    console.log('Not Sure');
  };
  useEffect(() => {
    rehydrate();
    console.log(JSON.stringify(app));
  }, []);

  const navopen = () => {
    dispatch(setNavOpen(!visibility));
  };
  // const divStyle = {
  //   color: 'blue',
  //   backgroundImage: 'url(' + {banner} + ')',
  // };
  const [header, setHeader] = useState(false);
  const [headbanner, setheadbanner] = useState(false);
  const certificate = () => {
    if (location.pathname.includes('courses') || location.pathname.includes('Courses') )
    {
      setHeader(true);
      setheadbanner(false);
      console.log(header);
    } else if (  location.pathname === '/'){
      setHeader(true);
      setheadbanner(false);
    }
     else{
      setHeader(false);
      setheadbanner(true);
     }
  };
  useEffect(() => {
    certificate();
  }); 

  return (
    <>
      <div className="content">
        <Loader></Loader>

        <div className="App">
          {header && <Header></Header>}
          {headbanner && <Headbanner></Headbanner>} 
          <div id="content">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="row ">
                <img
                  src={btnfilter}
                  alt=""
                  id="sidebarCollapse"
                  onClick={navopen}
                  className="img-fluid fa-align-left hamburgerMenu"
                ></img>
              </div>
            </nav>
          </div>
          <div className="container-fluid allScreen footerHelp m-0 p-0">
            <div className="row m-0 p-0">
              {!visibility && (
                <div className="col-md-4">
                  <Sidebar></Sidebar>
                </div>
              )}
              <div className="col-md-12 p-0">
                <Switch>
                  <Suspense fallback="Loading...">
                    <Route exact path="/" component={Courses} />
                    <Route exact path="/courses/" component={Courses} />
                    <Route exact path="/coursesAuth/" component={CourseAuth} />
                    <Route exact path="/test/" component={Test} />
                    <Route exact path="/thankyou/:id" component={ThankYou} />
                    <Route exact path="/result/:id" component={Result} />
                    <Route exact path="/coursesall/" component={Courses} />
                    <Route exact path="/video/:id/" component={Video} />
                    <Route
                      exact
                      path="/admin/category/"
                      component={CategoryModal}
                    />
                    <Route
                      exact
                      path="/admin/subcategory/"
                      component={SubCategoryModal}
                    />
                    <Route exact path="/admin/partner/" component={Partner} />
                    <Route exact path="/admin/sponsor/" component={Sponsor} />
                    <Route
                      exact
                      path="/admin/addcourses/"
                      component={uploadCourse}
                    />
                    <Route
                      exact
                      path="/admin/editcourse/:id"
                      component={uploadCourse}
                    />
                    <Route
                      exact
                      path="/admin/loginreport/"
                      component={LoginReport}
                    />
                    <Route
                      exact
                      path="/admin/videoreport/"
                      component={VideoReport}
                    />
                    <Route
                      exact
                      path="/courses/search/:query/"
                      component={SearchResult}
                    />
                    <Route
                      exact
                      path="/courses/filter/:query/"
                      component={CategoryFilter}
                    />
                    <Route path="/Certificate/" component={Certificate} />
                    <Route path="/user-profile/" component={UserProfile} />
                    <Route exact path="/test/:id/" component={Test} />
                  </Suspense>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default App;
