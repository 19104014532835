import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import btnclose from '../assets/images/btnclose.png';
import { CourseService } from '../services/courseService';
import { IsAuthenticated } from '../helpers/withAuthentication';
import { useForm } from 'react-hook-form';
import './subCategoryModal.css';
import { SubCategoryDto } from '../models/SubCategoryDto';
import { CategoryDto } from '../models/CategoryDto';
import { useDispatch } from 'react-redux';
import { setToastShow } from '../redux/actions/app';
interface ISubCategoryModal {
  show: boolean;
}
export const SubCategoryModal: React.FC<ISubCategoryModal> = (props) => {
  const { register, handleSubmit } = useForm<SubCategoryDto>();
  const [show, setShow] = useState(props.show);
  const [categoryList, setCategoryList] = useState(new Array<CategoryDto>());
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const getCategories = async () => {
    const courseApi: CourseService = new CourseService();
    setCategoryList(await courseApi.getCategory());
    // console.log(JSON.stringify(categoryList));
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    const value = categoryList.filter(function (item) {
      return item.id == e.target.value;
    });
    console.log(value[0].id);
  };
  const onSubmit = handleSubmit(async (data) => {
    const delayInMilliseconds = 2000;
    console.log(data);
    const courseservice: CourseService = new CourseService();
    const token = await courseservice.addnewsubcategory(data);
    dispatch(setToastShow(true));
    setTimeout(function() {
      handleClose();
    }, delayInMilliseconds); 
    console.log(token);
  });
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <form>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="bg-transparent col-md-12 col-sm-12 modelDim"
        centered
      >
        <Modal.Body className="bg-transparent">
          <div className="container-fluid p-0 m-0 bg-transparent">
            <img
              src={btnclose}
              alt=""
              className="closeButton"
              onClick={handleClose}
            ></img>
            <div className="container">
              <div className="row w modalform">
                <form onSubmit={onSubmit} className="subcatform">
                  <div className="row subcatalign">
                    <div className="form-group col-md-5">
                      <div className="lbl">
                        <label>
                          Course Category <span className="color">* </span>
                        </label>
                      </div>
                      <div className="input-group">
                        <select
                          className="form-control input-text"
                          name="categoryHeader"
                          onChange={handleChange}
                          ref={register({ required: true })}
                        >
                         
                          {categoryList.map(function (category, key) {
                            return (
                              <option key={key} value={category.id}>
                                {category.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="form-group col-md-5">
                      <div className="lbl">
                        <label>
                          Type New Sub Category{' '}
                          <span className="color">* </span>
                        </label>
                      </div>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control input-text"
                          placeholder="Exiting / Add New Sub Category"
                          name="name"
                          ref={register({ required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <Button
                      id="submit"
                      className="btn btnsubmit btnadd col-md-4"
                      type="Add"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default IsAuthenticated(SubCategoryModal, false);
