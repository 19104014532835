import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IsAuthenticated } from '../../helpers/withAuthentication';
import { UserDto } from '../../models/UserDto';
import './videoReport.css';
import imgAddVideo from '../../assets/images/imgAddVideo.png';
import { CSVLink } from 'react-csv';
// import {
// //  LineChart,
//  // Line,
// //  XAxis,
// //  YAxis,
// //  CartesianGrid,
//  / Tooltip,
//   Label,
// } from 'recharts';
// import { ReportAllViewDTO } from '../../models/ReportAllViewDto';
import { ReportService } from '../../services/reportService';
import { CourseDto } from '../../models/CourseDto';
import { CourseService } from '../../services/courseService';

const VideoReport: React.FC = () => {
  const { register } = useForm<UserDto>();
  const [allViews, setAllViews] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [testCount, setTestCount] = useState(0);
  const [certCount, setCertCount] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  
  const [views, setViews] = useState(0);
  const user: UserDto = JSON.parse(localStorage.getItem('user'));

  const [courseList, setCourseList] = useState(new Array<CourseDto>());
  const getCourses = async () => {
    const courseApi: CourseService = new CourseService();
    setCourseList(await courseApi.getCourses('-1'));
  };

  const getAllView = async (id: string) => {
    setIsLoad(true);
    const reportApi: ReportService = new ReportService();
    setAllViews(await reportApi.getVideoReport(id));
    setIsLoad(false);
    console.log('sdsd');
  };
  // const getReportData = async (unit) => {
  //   if (unit == 'week') {
  //     setReportName('Weekly Report');
  //   }
  //   if (unit == 'month') {
  //     setReportName('Monthly Report');
  //   }
  //   if (unit == 'year') {
  //     setReportName('Yearly Report');
  //   }
  //   if (unit == 'day') {
  //     setReportName("This Week's Report");
  //   }
  //   const reportApi: ReportService = new ReportService();
  //   setData(await reportApi.getVideoReportData(unit));
  // };

  const getVideoCount = async () => {
    // console.log(JSON.stringify(all));
    const reportApi: ReportService = new ReportService();
    const countData = await reportApi.getVideoCount();
    setVideoCount(countData['count']);
  };
  const getCertCount = async () => {
    // console.log(JSON.stringify(all));
    const reportApi: ReportService = new ReportService();
    const countData = await reportApi.getCertCount();
    setCertCount(countData);
  };
  const getTestCount = async () => {
    // console.log(JSON.stringify(all));
    const reportApi: ReportService = new ReportService();
    const countData = await reportApi.getTestCount();
    setTestCount(countData);
  };
  const getViews = async () => {
    // console.log(JSON.stringify(all));
    const reportApi: ReportService = new ReportService();
    const countData = await reportApi.getVideoViews();
    setViews(countData);
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    if (e.target.value != -1) {
      const value = courseList.filter(function (item) {
        return item.id == e.target.value;
      });
      getAllView(value[0].id);
    }
  };
  useEffect(() => {
    getVideoCount();
    getCertCount();
    getTestCount();
    getViews();
  }, []);

  useEffect(() => {
    getAllView('-1');
  }, []);
  useEffect(() => {
    getCourses();
  }, []);

  // useEffect(() => {
  //   getReportData('day');

  // }, []);
  function formatTimestamp(timeStamp) {
    const dateTime = new Date(timeStamp);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const year = dateTime.getFullYear();
    const month = months[dateTime.getMonth()];
    const day = dateTime.getDate();
    const date = (`${day} ${month} ${year}`);
    const hour = dateTime.getHours();
    const min = dateTime.getMinutes();
    const time = (`${hour <= 12 ? hour : (hour - 12)}:${min} ${hour < 12 ? 'AM' : 'PM'}`);
    return (`${date}, ${time}`);
  }

  return (
    <form>
      <div className="container-fluid col-md-12">
        <div className="container">
          <div className="row rowmargin">
            <div className="col-md-7">
              {/* <Button
                className="btnheader"
                onClick={() => getReportData('day')}
              >
                This Week
              </Button>
              <Button
                className="btnheader"
                onClick={() => getReportData('week')}
              >
                Weekly
              </Button>
              <Button
                className="btnheader"
                onClick={() => getReportData('month')}
              >
                Monthly
              </Button>
              <Button
                className="btnheader"
                onClick={() => getReportData('year')}
              >
                Yearly
              </Button> */}
            </div>
            {/* <div className="col-md-5">
              <div className="">
                 <input
                  type="date"
                  id="fromdate"
                  name="fromdate"
                  placeholder="Start Time"
                  className="form-group inputdate"
                />
                <input
                  type="date"
                  id="todate"
                  name="todate"
                  placeholder="End Time"
                  className="form-group inputdate"
                /> 
              </div>
            </div> */}
            {/* <div className="form-group col-md-4">
              <div className="input-group">
                <select
                  className="form-control input-text shadow-none"
                  name="video"
                  onChange={handleChange}
                  ref={register({ required: true })}
                >
                   {courseList.map(function (course, key) {
                            return (
                              <option key={key} value={course.id}>
                                {course.name}
                              </option>
                            );
                          })}
                </select>
              </div>
            </div>*/}
          </div>
          <div className="row headerbg">
            <div className="row col-md-12 mt-4 ">
              <div className="col-md-8 row">
                <div className="headername">
                  <h3>Hello ,</h3>
                  <h4> {user.fullName}</h4>
                </div>
                {/* <div className = "col-md-4">
           <input
                  type="date"
                  id="fromdate"
                  name="fromdate"
                  placeholder="Start Time"
                  className="form-group inputdate headerdate"
                />
             </div> */}
              </div>
              <div className="col-md-4 text-right">
                <Button className="home " type="button">
                  <i className="fa fa-home" aria-hidden="true"></i>Home Screen
                </Button>
              </div>
            </div>

            <div className="row col-md-12">
              <div className="col-md-7 mt-5">
                <button className=" btnstatsVideo btn-danger btn">
                  Videos
                  <h3>{videoCount}</h3>
                </button>
                <button className=" btnstatsVideo btn-danger btn">
                  Views
                  <h3>{views}</h3>
                </button>
                <Button className=" btnstats">
                  Tests <h3>{testCount}</h3>
                </Button>
                <Button className=" btnstats">
                  Certifications <h3>{certCount}</h3>
                </Button>
              </div>
              <div className="col-md-5">
                <img
                  src={imgAddVideo}
                  alt=""
                  className="imgvideo img-fluid"
                ></img>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-9 row mt-2">
              <div className="form-group col-md-9 ">
                <div className="input-group">
                  <select
                    className="form-control input-text shadow-none mt-2"
                    name="video"
                    onChange={handleChange}
                    ref={register({ required: true })}
                  >
                    {' '}
                    <option value={-1}>All Courses</option>
                    {courseList.map(function (course, key) {
                      return (
                        <option key={key} value={course.id}>
                          {course.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-3 row mt-2">
              <CSVLink data={allViews}>Export Data</CSVLink>
            </div>
          </div>

          <div className="rowmargin allviewtable">
            <table className="table">
              <thead className="tableHeader">
                <tr>
                  {/* <th scope="col">Sr.No</th> */}
                  <th scope="col" className="tableHeaderCol text-uppercase">Name</th>
                  {/* <th scope="col">Email Id</th> */}
                  <th scope="col" className=" tableHeaderCol text-uppercase">Video Series</th>
                  {/* <th scope="col">Status</th> */}
                  <th scope="col" className="tableHeaderCol text-uppercase">Date & Status</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Certification</th>
                  <th scope="col" className="tableHeaderCol text-uppercase">Feedback</th>
                </tr>
              </thead>
              <tbody>
                {allViews.map((view, index) => (
                  <tr key={index}>
                    {/* <td scope="col"> {index}</td> */}
                    <td scope="col" className="tdTitle">{view.user.fullName}</td>
                    {/* <td scope="col">{view.user.email}</td> */}
                    <td scope="col" className="tdTitle">{view.course.name}</td>
                    {/* <td scope="col">Watched</td> */}
                    <td scope="col" className="tdTitle">{formatTimestamp(view.timestamp)}</td>
                    <td scope="col" className="tdTitle">...</td>
                    <td scope="col" className="tdTitle">...</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isLoad && <p>Loading ...</p>}
            {allViews.length < 1 && <p></p>}
            
          </div>
        </div>
      </div>
    </form>
  );
};

export default IsAuthenticated(VideoReport, false);
