import React, { useEffect } from 'react';
import './signUp.css';
import { UserDto } from '../models/UserDto';
import _ from 'lodash/fp';
import {
  setAuthentication,
  setShowSingUp,
  setCurrentUser,
  setIsLoading,
} from '../redux/actions/app';
import { AuthService } from '../services/authService';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

interface ISignUpForm {
  userData: UserDto;
  isScocial: boolean;
}
const SignUpForm: React.FC<ISignUpForm> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, setValue, errors } = useForm<UserDto>();
  const obj = {
    array: [],
  };
  for (let l = 15; l < 100; l++) {
    obj.array[l] = l + 1;
  }
  const optionItems = obj.array.map((item) => (
    <option key={item}>{item}</option>
  ));
  const handleClose = () => {
    dispatch(setShowSingUp(false));
    history.push('/courses');
  };

  const onSubmit = handleSubmit(async (data) => {
    dispatch(setIsLoading(true));
    const authService: AuthService = new AuthService();
    if (props.isScocial == false) {
      data.provider = 'LOCAL';
      const user: UserDto = await authService.addnewuser(data);
      dispatch(setAuthentication(true));
      dispatch(setShowSingUp(false));
      dispatch(setCurrentUser(user));
      dispatch(setIsLoading(false));
      history.push('/courses');
    }
    if (props.isScocial == true) {
      data.providerId = props.userData.providerId;
      data.provider = props.userData.provider;
      data.signUpComplete = true;
      const user: UserDto = await authService.connectSocialComplete(data);
      dispatch(setAuthentication(true));
      dispatch(setShowSingUp(false));
      dispatch(setCurrentUser(user));
      dispatch(setIsLoading(false));
      history.push('/courses');
    }

    console.log(JSON.stringify(props));
  });

  useEffect(() => {
    setValue('fullName', props.userData.fullName);
    setValue('email', props.userData.email);
  }, []);

  return (
    <>
      <div id="dvsignupdtls" className="div-wrap">
        <form onSubmit={onSubmit}>
          <div className="form-group row">
            <div className="input-group">
              <span className="color">*</span>
              {_.get('fullName.type', errors) === 'pattern' && (
                <p className="validation">Alphabetical characters only</p>
              )}
              <input
                type="text"
                placeholder="Full Name"
                className="form-control inputstyle"
                name="fullName"
                ref={register({
                  required: true,
                  maxLength: 20,
                  pattern: /^[a-zA-Z ]*$/,
                })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="input-group">
              <span className="color">*</span>
              <input
                className="form-control inputstyle"
                type="email"
                name="email"
                ref={register({ required: true })}
                placeholder="Email ID"
              />
            </div>
          </div>
          <div className="form-group row" hidden={props.isScocial}>
            <div className="input-group">
              <span className="color">*</span>
              {_.get('password.type', errors) === 'minLength' && (
                <p className="validation">More than 8 characters only </p>
              )}
              <input
                className="form-control inputstyle"
                type="password"
                name="password"
                ref={register({ required: !props.isScocial, minLength: 8 })}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="input-group">
              <span className="color">*</span>
              {_.get('location.type', errors) === 'required' && (
                <p className="validation">Location is required</p>
              )}
              <input
                className="form-control inputstyle"
                type="text"
                name="location"
                ref={register({ required: true })}
                placeholder="Location"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="input-group">
              <span className="color">*</span>
              {_.get('mobileNumber.type', errors) === 'required' && (
                <p className="validation">MobileNumber is required</p>
              )}
              <input
                className="form-control inputstyle"
                type="number"
                name="mobileNumber"
                ref={register({ required: true, maxLength: 12 })}
                placeholder="Mobile Number"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="input-group">
              {_.get('profession.type', errors) === 'pattern' && (
                <p className="validation">Alphabetical characters only</p>
              )}
              <input
                className="form-control inputstyle"
                type="text"
                name="profession"
                placeholder="Your Profession"
                ref={register({ pattern: /^[a-zA-Z]+$/ })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="input-group">
              <span className="color">*</span>
              {_.get('age.type', errors) === 'required' && (
                <p className="validation">Age is required</p>
              )}
              <select
                className="form-control inputstyle"
                name="age"
                ref={register({ required: true })}
              >
                <option>Age</option>
                {optionItems}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <div className="input-group">
              <span className="color">*</span>
              {_.get('gender.type', errors) === 'required' && (
                <p className="validation">Gender is required</p>
              )}
              <select
                className="form-control inputstyle"
                name="gender"
                ref={register({ required: true })}
              >
                <option>Gender</option>
                <option value="Male">Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
            </div>
          </div>
          <div className="row p-2">
            <Button
              id="cancel"
              className="btn btnreset col mx-1"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              id="submit"
              className="btn btnsubmit col mx-1"
              type="Submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUpForm;
