import React from 'react';
import { useSelector } from 'react-redux';
import './loader.css';

function Loader() {
  
  const isLoading: boolean = useSelector((state) => state['app'].isLoading);
 
  return (
      <>
      {isLoading && <div className="loader"></div>}
      {!isLoading && <div className=""></div>}
      </>
    
  );
}

export default Loader;
