import React, { useEffect } from 'react';
import { CourseDto } from '../models/CourseDto';
import './coursesTile.css';
import { useHistory } from 'react-router-dom';
import { IsAuthenticated } from '../helpers/withAuthentication';
//import btnPlay from '../assets/images/btnplay.png';
import './videoComponent.css';
import { useSelector } from 'react-redux';
//import { setAuthentication } from '../redux/actions/app';
import { AppStateModel } from '../redux/reducers/app';

interface IVideoCourseTile {
  course: CourseDto;
}

const VideoCoursesTile: React.FC<IVideoCourseTile> = (props) => {
  //const dispatch = useDispatch();
  const history = useHistory();
  const see: AppStateModel = useSelector((state) => state['app']);  
  const visibility = true;
  const playVideo = () => {
    console.log('PlayClicked');
    history.push('/video/' + props.course.id);
  };
  useEffect(() => {
    //dispatch(setAuthentication(true));
    console.log(see.isAuthenticated);
  }, []);
  return (
    <div className="container-fluid borderBottom">
      <div className="vcard-item row align-items-center " onClick={playVideo}>
        <div className={visibility ? 'col-md-4 text-left px-0' : 'col text-left'}>
          <div className="containerVImg">
            <img
              className="img-thumbnail image px-0"
              src={props.course.image}
              alt=""
            ></img>
            <div className="overlay">
              <button className="btn icon" title="play" onClick={playVideo}>
                <i className="fas fa-play-circle"></i>
              </button>
            </div>
          </div>
        </div>
        <div className={visibility ? 'col-md-8 text-left' : 'col text-left'}>
          <span className="videosubheader">{props.course.name}</span>
           <p className="videotext">
          {props.course.description.substring(0,40)}...
          </p> 
        </div>
      </div>
    </div>
  );
};

export default IsAuthenticated(VideoCoursesTile, true);
