import React from 'react';
import './footer.css';
// import foote.rlogo from '../assets/images/assistlogo_footer.png';

const Footer: React.FC = () => {
  return (
    <footer className="footerC copyright">
      © Copyright Asia Society for Social Improvement and Sustainable
      Transformation (ASSIST) 2012-2021. All Rights reserved.
    </footer>
  );
};

export default Footer;
